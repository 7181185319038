/* app/page/landing */
import $ from 'jquery';
import { ListingFilterSort } from 'App/listing/filter-sort';
import { ListingFilterExpand } from 'App/listing/filter-expand';
import { Pods } from 'App/pods/pods'; 

'use strict';

var $filter = $('.js-filter');

var module = {
	init() {
		if ($filter.length) {
			ListingFilterSort.init($filter);
			ListingFilterExpand.init($filter);
		}
	}
}

export { module as PageLanding }