import $ from 'jquery';
import { Confirm } from 'App/confirm/confirm';

var PermissionStates = {
	PENDING: 0, // Initial state
	NO: 1, // User selected "no" button
	YES: 2, // User selected "yes" button
	BLOCKED: -1 // User selected "yes" but blocked browser permission
};

var selectors = {
	geolocation: '.js-permission-location-check',
	cookie: '.js-cookie-confirm'
};

var support = {
	localStorage: !!window.localStorage && ('setItem' in window.localStorage),
	permissions: 'permissions' in navigator,
	geolocation: 'geolocation' in navigator,
	cookie: true
};
var permissionApiSupport = {
	geolocation: true
};

// Supported permissions must have an entry in selectors and support
var permissions = {
	geolocation: PermissionStates.PENDING,
	cookie: PermissionStates.PENDING
};

var Permissions = {
	get: function (options) {
		// Checks the state of a permission,
		// then calls an appropriate callback

		// Options has one required parameter and three optional parameters:
		// type (required): a string representing the permission being checked
		// yes: a function to be called if/when the permission is granted
		// no: a function to be called if/when the permission is denied
		// prompt: a function to be called if the user is prompted to give permission

		/* Example use:
			Permissions.get(
				{
					type: 'geolocation',
					yes: yesCallback,
					no: noCallback,
					prompt: promptCallback
				}
			);
		*/

		var type = options.type;
		var allowed;

		// First check that this Permissions script supports the requested API
		if (!(type in permissions)) {
			var supportedPermissions = [];
			for (var i in permissions) {
				supportedPermissions.push(i);
			}
			console.error('Permissions for ' + type + ' not supported. Supported permissions are ', supportedPermissions.join(', '));
			options.no();
			return;
		}

		// Permissions are only relevant if the API is supported
		if (support[type]) {

			// If the permission state has already been determined, act immediately
			if (permissions[type] !== PermissionStates.PENDING) {
				Permissions._permissionDetermined(options, permissions[type]);
				return;
			}

			if (support.permissions && permissionApiSupport[type]) {
				// Permission can be checked directly
				// However, this is asynchronous, so a callback is necessary
				navigator.permissions.query({ 'name': type }).then(Permissions._checkPermissionApi(options));
				return;
			} else {
				allowed = Permissions._checkPermissionStorage(options);
				Permissions._permissionDetermined(options, allowed);
			}

		} else if (options.no) {
			options.no();
		}
	},

	_checkPermissionApi: function (options) {
		// Detecting permissions directly lets us re-prompt the user
		// if they rescind their permissions, instead of relying
		// solely on our own record in localStorage

		return function (permission) {

			var allowed;
			var allowedStorage;

			switch (permission.state) {
				case 'prompt':
					// Either the user has never been prompted by the browser,
					// or they have rescinded previous granted or blocked permissions.
					// Compare with stored value in localStorage, if possible

					allowedStorage = Permissions._checkPermissionStorage(options);

					if ((allowedStorage === PermissionStates.BLOCKED) ||
						(allowedStorage === PermissionStates.YES)) {
						// User had previously granted or blocked this permission, then
						// rescinded that. So they should be offered another prompt
						allowed = PermissionStates.PROMPT;
					} else {
						// State could either be initial, or that user selected "no",
						// so preferentially use a value in localStorage if present
						allowed = allowedStorage;
					}
					break;
				case 'granted':
					allowed = PermissionStates.YES;
					break;
				case 'denied':
					allowed = PermissionStates.BLOCKED;
					break;
			}

			Permissions._permissionDetermined(options, allowed);
		};
	},

	_checkPermissionStorage: function (options) {
		// Use localStorage as a backup to record the permission state

		var allowed;

		if (support.localStorage) {
			allowed = localStorage.getItem('permissions-' + options.type);
			if (allowed === null) {
				allowed = undefined;
			} else {
				// localStorage stores everything as a string,
				// but this should be a number
				allowed = parseInt(allowed, 10);
			}
		}

		return allowed;
	},

	_permissionDetermined: function (options, allowed) {
		// We've determined the current permission,
		// so call the appropriate callback

		var type = options.type;

		if (typeof allowed !== 'undefined') {
			Permissions._setPermission(type, allowed);
		}

		if (permissions[type] === PermissionStates.YES) {
			if (options.yes) {
				options.yes();
			}
		} else if (permissions[type] === PermissionStates.NO || permissions[type] === PermissionStates.BLOCKED) {
			if (options.no) {
				options.no();
			}
		} else {
			// PENDING state or undetermined
			// Callbacks will be delayed for user confirmation
			Permissions._requestPermission(options);
		}
	},

	_requestPermission: function (options) {
		// Let the user know why permission is needed,
		// and ask them before having the browser prompt them

		var $popup = $(selectors[options.type]);

		if ($popup.length) {
			Confirm.check(
				$popup,
				Permissions._permissionGranted(options),
				Permissions._permissionDenied(options)
			);
			Permissions._permissionPrompted(options);
		} else {
			// No field exists to tell users why we need permission,
			// so have the browser prompt them directly
			Permissions._permissionGranted(options)();
		}
	},

	_permissionGranted: function (options) {
		// The user selected "yes", and will now be
		// prompted for permission by the browser.

		// They will not be prompted again unless they
		// rescind this permission and we have access
		// to the permissions API

		return function () {
			Permissions._setPermission(options.type, PermissionStates.YES);
			if (options.yes) {
				options.yes();
			}
		};
	},

	_permissionDenied: function (options) {
		// The user selected "no".

		// They will not be prompted again unless
		// this is removed from localStorage

		return function () {
			Permissions._setPermission(options.type, PermissionStates.NO);
			if (options.no) {
				options.no();
			}
		};
	},

	_permissionPrompted: function (options) {
		// The user has been prompted for permission

		if (options.prompt) {
			options.prompt();
		}
	},

	_setPermission: function (type, allowed) {
		permissions[type] = allowed;
		if (support.localStorage) {
			localStorage.setItem('permissions-' + type, permissions[type]);
		}
	}
};

export { Permissions };
