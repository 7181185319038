/* app/ui/rotator/rotator */

import $ from 'jquery';
import 'brotator';

var Rotator;
var $items;
var $featureRotator = null;
var $content = null;
var $viewport = null;
var autoRotate = false;

var module = {

	init: function () {
		Rotator = this;
		$featureRotator = $('.js-rotator');
		$content = $featureRotator.find('.js-rotator__content');
		$viewport = $featureRotator.find('.js-rotator__viewport');
		$items = $content.find('li');
		autoRotate = $featureRotator.hasClass('is-auto');

		if ($items.length > 1) {
			$featureRotator.brotator({
				content: '.js-rotator__content',
				timeout: 5000,
				hasMenu: false,
				hasButtons: true,
				next: '.js-rotator__next',
				previous: '.js-rotator__prev',
				menu: '.js-rotator__menu',
				menuItem: '.js-rotator__menu-item',
				animationSpeed: 500,
				lazyloader: true,
				namespace: '/rotator',
				autorotate: autoRotate
			});
		}
	}
};


var Rotator = {
	init: module.init
};

export { Rotator };