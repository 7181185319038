define(
	[
	'jquery'
	],
	function( $ ) {
		if ($('.js-share-wrapper').length) {
			$(document).on('click touchstart', function(e) {
				if ($(e.target).closest('.js-share-wrapper').length > 0) {
					if ($(e.target).closest('.js-share-wrapper.active').length > 0) {
						// do nothing
						return;
					} else {
						$('.js-share-wrapper').toggleClass('active');
					}
				} else {
					if ($('.js-share-wrapper').hasClass('active')) {
						$('.js-share-wrapper').removeClass('active');
					}
				}
			});
		}
	}
);
