/* app/ui/map/headermap */
import $ from 'jquery';
import jQuery from 'jquery';
import 'geoxml3';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var apiLoaded = false;
var mapStyles = {};

var $window;
var $header;
var $mapToggle;
var $mapContainer;
var $checkboxes;
var checkboxInitTimeout;

var baseKmlUrls = [];
var parser;
var parserLayer;
var gmap;
var infoBoxes = [];
var departureMarkers = [];
var kmlLayers = [];

var HeaderMap = {
	init: function () {

		$window = $(window);
		$header = $('.js-header');
		$mapToggle = $('.js-header-map-toggle');
		$mapContainer = $('.js-map-wrapper');
		$checkboxes = $('.filter-checkbox');

		mapStyles = HeaderMap._externalMapStyles;

		baseKmlUrls = [
			'/Content/kml/national-parks.kml'
			// '/Content/kml/walking-tracks.kml',
		];

		//if ($mapContainer.length) {
			HeaderMap._initSubscriptions();
			HeaderMap._initEvents();
		//}
	},

	// Listen for Maps API load event
	// 1. Published by homepage map
	// 2. Published if header map forces API to load
	_initSubscriptions: function () {
		subscribe('/map/apiLoaded', HeaderMap._updateApiStatus);
		subscribe('/headermap/apiLoaded', HeaderMap._apiLoaded);
		subscribe('/headermap/toggle', HeaderMap._toggleMapDisplay);
	},

	// Event listeners
	// 1. Header map toggle click
	// 2. Header map checkboxes click
	_initEvents: function () {
		$mapToggle.on('click', HeaderMap._onMapToggleClick);
		$('.header-map-close').on('click', HeaderMap._toggleMapDisplay);
		$checkboxes.on('change map-init', HeaderMap._processCheckboxChange);
	},

	// Update module variables from subscription data
	_updateApiStatus: function () {
		apiLoaded = true;
	},

	// Process map toggle click event
	// If API is already loaded:
	// 1. Init map if it hasn't been already
	// 2. Toggle map display
	// If API not loaded:
	// Publish load event
	_onMapToggleClick: function (event) {
		event.preventDefault();
		if (apiLoaded) {
			HeaderMap._toggleMapDisplay();
			if (!$mapContainer.hasClass('is-loaded')) {
				HeaderMap._rjMapInit();
			}
		} else {
			publish('/map/loadApi', { mapType: 'header' });
		}
	},

	// Runs when Maps API forced to load by header map
	// Updates module variables
	// Initialises map
	// Toggles map
	_apiLoaded: function () {
		HeaderMap._updateApiStatus();
		HeaderMap._toggleMapDisplay();
		HeaderMap._rjMapInit();
	},

	// Toggles map display
	_toggleMapDisplay: function () {
		var mapTopPos = $header.hasClass('nav-up') ? 0 : $header.outerHeight();
		var mapHeight = $window.height() - mapTopPos;

		$mapContainer.css({
			height: mapHeight,
			top: mapTopPos
		});

		publish('/megamenu/close');

		if (!$mapContainer.hasClass('is-expanded')) {
			$mapContainer.addClass('is-expanded');
		}

		if (!$mapContainer.hasClass('is-visible')) {
			$mapToggle.closest('.icon').addClass('is-active');
			setTimeout(function () {
				$mapContainer.addClass('is-visible');
			}, 125);
		}
		else if ($mapContainer.hasClass('is-visible')) {
			publish('/map/collapsed');
			$mapContainer.removeClass('is-visible');
			$mapToggle.closest('.icon').removeClass('is-active');
		}
	},

	_rjMapInit: function () {
		$mapContainer.addClass('is-loaded');

		window.jQuery = $;
		var gmapScript = document.createElement('script');
		gmapScript.type = 'text/javascript';
		gmapScript.src = '/Content/scripts/src/lib/gmap3.js';

		var infoboxScript = document.createElement('script');
		infoboxScript.type = 'text/javascript';
		infoboxScript.src = '/Content/scripts/src/lib/gmap-infobox.js';
		// LOAD GMAP PLUGIN
		$mapContainer.after(gmapScript, infoboxScript);
		HeaderMap._createGoogleMap();
		HeaderMap._addMapTypeControl();
		HeaderMap._addFiltersControl();

		parser = new geoXML3.parser({
			map: gmap,
			processStyles: false,
			zoom: false,
			suppressInfoWindows: true
		});
		parserLayer = parser.docs;

		for (var i in baseKmlUrls) {
			if (baseKmlUrls.hasOwnProperty(i)) {
				parser.parse(baseKmlUrls[i]);
			}
		}

		// GET ALL MAP DATA
		var mapId = $('[data-map-id]').attr('data-map-id');
		var mapType = $('[data-map-type]').attr('data-map-type');

		if (typeof (mapId) !== 'undefined' && mapId !== '') {
			if (typeof (mapType) !== 'undefined' && mapType === 'experience') {
				// Show hidden legend filter
				$('.js-map-filter-legend').show();

				// Show experience layer
				$.getJSON('/map/json/main/map-data.json',
					function (data) {
						HeaderMap._buildRJMap(data, [mapId]);
					});
			} else if (typeof (mapType) !== 'undefined' && mapType === 'destination') {

				// Show single destination
				$.getJSON('/map/json/main/destinations.json',
					function (data) {
						HeaderMap._buildRJMap(data, [mapId]);
					});
			}
		} else {
			// Show destinations
			var destinationsUrl = '/map/json/main/destinations.json';
			var layerNames = ['region-milford', 'region-doubtful', 'region-teanau', 'region-queenstown', 'region-stewart', 'region-dusky'];

			if ($('.theme-sie').length) {
				destinationsUrl = '/map/json/sie/destinations.json';
				layerNames = ['region-bluff', 'region-invercargill', 'region-oban'];
			}

			$.getJSON(destinationsUrl,
				function (data) {
					HeaderMap._buildRJMap(data, layerNames);

					var centralRegion;

					if ($('.theme-sie').length) {
						// Use oban a a central position
						centralRegion = data['region-oban'];
					} else {
						// Use Queenstown as a central position
						centralRegion = data['region-queenstown'];
					}

					var centralRegionCenter = HeaderMap._layerCenterPos(centralRegion);
					var centralRegionZoom = centralRegion.zoomLevel;

					gmap.panTo(centralRegionCenter);
					gmap.setZoom(centralRegionZoom);
				});
		}
	},

	_createGoogleMap: function () {
		gmap = new google.maps.Map(document.getElementById('headermap'), {
			center: { lat: -45.0311622, lng: 168.66264350000006 },
			zoom: 7,
			streetViewControl: false,
			mapTypeControl: false,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP
			},
			styles: mapStyles
		});

		// Debug function to help with KML routes if they need tweaking
		// HeaderMap.setClickListener(gmap, HeaderMap.displayLatLng);
	},

	_displayLatLng: function (e) {
		console.log('LAT: ' + e.latLng.lat() + ', LNG: ' + e.latLng.lng());
	},

	_addMapTypeControl: function () {
		// Create the div
		var mapTypeControlDiv = document.createElement('div');
		$(mapTypeControlDiv).addClass('control--wrapper map-control--wrapper');
		var maps = {
			'Map': 'ROADMAP',
			'Satellite': 'SATELLITE',
			'Terrain': 'TERRAIN'
		};
		for (var i in maps) {
			if (maps.hasOwnProperty(i)) {
				var mapTypeControlUI = document.createElement('div');
				$(mapTypeControlUI).addClass('control').attr('title', 'Switch to ' + i).attr('data-map-type', maps[i]);
				mapTypeControlDiv.appendChild(mapTypeControlUI);

				var mapTypeControlText = document.createElement('span');
				mapTypeControlText.innerHTML = i;
				mapTypeControlUI.appendChild(mapTypeControlText);
				HeaderMap._setClickListener(mapTypeControlUI, HeaderMap._setMapType);
			}
		}
		gmap.controls[google.maps.ControlPosition.TOP_LEFT].push(mapTypeControlDiv);
	},

	_addFiltersControl: function () {
		// Create a div to hold the control.
		var filterControlDiv = document.getElementById('filterControl');
		$(filterControlDiv).addClass('control--loaded');
		HeaderMap._setClickListener(filterControlDiv, HeaderMap._toggleFilterState);
		HeaderMap._setClickListener(gmap, HeaderMap.closeFilters);
		gmap.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(filterControlDiv);
	},

	_setMapType: function (event) {
		var mapType = $(this).data('map-type');
		gmap.setMapTypeId(google.maps.MapTypeId[mapType]);
		if ($('.activeMapType').length > 0) {
			$('.activeMapType').removeClass('activeMapType');
		}
		$(this).addClass('activeMapType');
	},

	_toggleFilterState: function (event) {
		var $filter = $(event.target).closest('.js-filter-control-wrapper');

		if ($filter.hasClass('is-open')) {
			if ($filter.attr('id') !== 'filterControl') {
				return false;
			} else {
				$filter.removeClass('is-open');
			}
		} else {
			$filter.addClass('is-open');
		}
	},

	_closeFilters: function (event) {
		if (!$(event.target).closest('#filterControl').length) {
			if ($('#filterControl').hasClass('is-open')) {
				$('#filterControl').removeClass('is-open');
			} else {
				return;
			}
		}
	},

	_processCheckboxChange: function (event) {
		var $thisCheckbox = $(this);
		var isNatParks = $thisCheckbox.attr('data-layer') === 'natparks';
		var action = $thisCheckbox.is(':checked') ? 'showDocument' : 'hideDocument';

		var natParksUrl = baseKmlUrls[0];
		var natParksLayer = parserLayer.filter(function (o) { return o.baseUrl === natParksUrl; });
		var natParksIndex = -1;
		if (natParksLayer && natParksLayer.length) {
			natParksIndex = parserLayer.indexOf(natParksLayer[0]);
		}

		if (isNatParks) {
			if (natParksIndex in parserLayer) {
				parser[action](parserLayer[natParksIndex]);
			}
		} else {
			for (var i = 0; i < parserLayer.length; i++) {
				if (i === natParksIndex) {
					continue;
				}
				parser[action](parserLayer[i]);
			}
		}

		// If not all of the layers have loaded again, try initialising the checkboxes again
		if ((parserLayer.length < $checkboxes.length) && (typeof checkboxInitTimeout === 'undefined')) {
			checkboxInitTimeout = window.setTimeout(function () {
				$checkboxes.trigger('map-init');
			}, 200);
		}
	},

	_setClickListener: function (element, callback) {
		google.maps.event.addDomListener(element, 'click', callback);
	},

	_buildRJMap: function (mapData, layerNames) {
		for (var i = 0; i < layerNames.length; i++) {
			var layer = mapData[layerNames[i]];
			HeaderMap._displayMapLayers(layer, '#', i > 0);
		}
		$checkboxes.trigger('map-init');
	},

	_displayMapLayers: function (layer, link, keepPrevious) {
		if (layer) {
			if (!keepPrevious) {
				// DELETE ANY CURRENT MARKERS
				HeaderMap._deleteOverlays(kmlLayers);
				HeaderMap._closeInfoBoxes(departureMarkers);
				HeaderMap._closeInfoBoxes(infoBoxes);
				$mapContainer.find('.info-window').empty().remove();
			}

			// HeaderMap.addInfoBox(layer, link);
			if (layer.markers.length >= 1) {
				HeaderMap._addMarkers(layer);
			}
			if (layer.kml.length >= 1) {
				HeaderMap._addKmlLayers(layer.kml);
			}
		}
	},

	_addMarkers: function (layer) {
		$(layer.markers).each(function () {
			var markerPos = new google.maps.LatLng(this.lat, this.lng);
			var pixelOffset = new google.maps.Size(2, -18);
			var width = 250;

			var cssClass = 'departure';
			if (this.markerType === 'destination') {
				width = 160;
				if (window.location.href.indexOf('ja') != -1) {
					width = 190;
				}
			}
			var style = { width: width + 'px' };

			if (this.orientation === "left") {
				cssClass += ' departure--left';
				pixelOffset = new google.maps.Size(-10 - width, -18);
			}
			var markerContentLang;
			if (window.location.href.indexOf('zh-cn') != -1) {
				markerContentLang = this.markerContent_zh_cn;
			}
			if (window.location.href.indexOf('ja') != -1) {
				markerContentLang = this.markerContent_ja;
			}
			if (!markerContentLang) {
				markerContentLang = this.markerContent;
			}
			var marker = new InfoBox({
				boxClass: cssClass,
				boxStyle: style,
				content: markerContentLang,
				position: markerPos,
				disableAutoPan: true,
				pixelOffset: pixelOffset,
				closeBoxURL: '/Content/images/interface/ind/ind-map-close.png',
				closeBoxMargin: '0'
			});
			departureMarkers.push(marker);
		});

		HeaderMap._showInfoBoxes(departureMarkers, layer);
	},

	_addKmlLayers: function (kmlObject) {
		var kmlLayerCount = kmlObject.length;

		if (kmlLayerCount) {
			$checkboxes.eq(1).prop('checked', true);

			for (var i = 0; i < kmlLayerCount; i++) {
				var kmlUrl = encodeURI('/map/kml/main/' + kmlObject[i].fileName);
				parser.parse(kmlUrl);
			}
		}
	},

	_showInfoBoxes: function (overlays, layer) {
		var center = new google.maps.LatLng(layer.lat, layer.lng);

		if (overlays) {
			gmap.setZoom(layer.zoomLevel);
			if (gmap.getCenter().equals(center)) {
				for (var i in overlays) {
					if (overlays.hasOwnProperty(i)) {
						overlays[i].open(gmap);
					}
				}
			} else {
				gmap.panTo(center);
				google.maps.event.addListenerOnce(gmap, 'idle', function () {
					for (var j in overlays) {
						if (overlays.hasOwnProperty(j)) {
							overlays[j].open(gmap);
						}
					}
				});
			}
		}
	},

	_closeInfoBoxes: function (overlays) {
		if (overlays) {
			for (var i in overlays) {
				if (overlays.hasOwnProperty(i)) {
					overlays[i].close();
				}
			}
			overlays.length = 0;
		}
	},

	_deleteOverlays: function (overlays) {
		if (overlays) {
			for (var i in overlays) {
				if (overlays.hasOwnProperty(i)) {
					overlays[i].setMap(null);
				}
			}
			overlays.length = 0;
		}
	},

	_layerCenterPos: function (layer) {
		var centerPos = new google.maps.LatLng(layer.lat, layer.lng);
		return centerPos;
	}
};

export { HeaderMap };