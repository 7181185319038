// sticky-header.js
// Based on code originally authored by Marius Cracinoiu. Modifed by Terabyte Interactive.

define(
	['jquery'],
	function($) {
	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('.js-header').outerHeight();

	$(window).on('scroll', function(event){
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
				// Scroll Down
				if ($('.mega-menu-backdrop').hasClass('active') || $('.menu').hasClass('active')) {
					return false;
				}
				else {
					$('body').addClass('nav-up');
					$('.js-header').addClass('nav-up');
				}
			} else {
				// Scroll Up
				if(st + $(window).height() < $(document).height()) {
					$('body').removeClass('nav-up');
					$('.js-header').removeClass('nav-up');
				}

			}
			lastScrollTop = st;
		}
	}
	);