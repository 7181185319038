/* util/core */
import $ from 'jquery';

var Core = {

	// Scroll to an element on screen
	// $elm = jQuery element - the element to scroll to
	// pxAbove = int - padding between top of screen and element (defaults to 0)
	_scrollToElm: function ($elm, pxAbove, speed, preventScrollDown) {
		var topPadding = pxAbove || 0;
		var scrollHeight = $elm.offset().top;
		var scrollTop = $(document).scrollTop();

		speed = speed || 'slow';

		if (!(preventScrollDown && scrollTop < scrollHeight - topPadding)) {
			$('html, body').animate({ scrollTop: scrollHeight - topPadding }, speed);
		}
	},

	// Scroll to an element on screen, taking into account the sticky header
	_scrollToElmSmart: function ($elm, pxAbove, speed, preventScrollDown) {
		// Default value of pxAbove is 0
		pxAbove = +pxAbove || 0;

		if (!$elm.length) {
			return;
		}

		var $header = $('.js-header');

		if ($header.length) {

			var headerHeight = $header.outerHeight();
			var headerOffset = headerHeight + $header[0].getBoundingClientRect().top;

			var topPadding = headerOffset + pxAbove;

			var currentScrollPos = $(document).scrollTop();
			var endScrollPos = $elm.offset().top - topPadding;

			if (endScrollPos < currentScrollPos) {
				// Scroll up, so header will appear
				Core._scrollToElm($elm, headerHeight + pxAbove, speed, preventScrollDown);
			} else if (endScrollPos > currentScrollPos) {
				// Scroll down, so header will hide
				Core._scrollToElm($elm, pxAbove, speed, preventScrollDown);
				topPadding -= headerHeight;
			} else {
				// Not scrolling at all
				return;
			}
		} else {
			Core._scrollToElm($elm, pxAbove, speed, preventScrollDown);
		}
	},

	// Returns a class name with no '.'
	// selector = string - the jQuery selector to remove '.' from
	_getClassName: function (selector) {
		return selector.replace('.', '');
	},

	// Creates a callback that, when bound to a keydown event,
	// will only trigger on that event when the "Enter" key is pressed.
	// Any other bound events (e.g. 'click' above) will always be triggered.

	// Example usage:
	// e.g. $(document).on('click keydown', selector, activate(callback));
	_activate: function (callback) {
		return function (e) {
			if (e.type !== 'keydown' || (e.which === 13 || e.which === 32)) {
				// Enter key (keycode 13)
				// Spacebar (keycode 32)
				callback.apply(this, arguments);
			}
		};
	}
};


export { Core };

