import $ from 'jquery';
import { UtilAjax } from 'Util/ajax';
import { Core } from 'Util/core';
import { LazyLoad } from 'Util/lazyload';
import { TrackLoad } from 'App/track/load';
import { Rotator } from 'App/rotator/rotator';
import { PromotionRotator } from 'App/rotator/promotion-rotator';
import { HeroVideo } from 'App/video/hero-video';
import { MapLoad } from 'App/map/map-load';
import { Tooltip } from 'App/tooltip/tooltip';
import { Dropdowns } from 'App/menu/dropdowns';
import { FormAjax } from 'App/form/ajax';
import { Cookie } from 'App/cookie/cookie';
import { HeroGallery } from 'App/hero-gallery/hero-gallery';
import { filteredPods } from 'App/filtered-pods/filtered-pods';
import { MediaQueries } from 'Util/mediaqueries';
import { RteToggle } from 'App/rte-toggle/rte-toggle';
import { Subs } from 'Util/subs';
import 'phatfingaz';
import 'fakeSelect';
import { Menu } from 'App/menu/menu';
import { MegaMenu } from 'App/mega-menu/mega-menu';
//import { MapHeader } from 'App/map-header/map-header';
import { ShareDropdown } from 'App/social/share-dropdown';
import { SidebarControls } from 'App/sidebar-controls/sidebar-controls';
import { StickyHeader } from 'App/sticky-header/sticky-header';
import 'magnificPopup';
import { publish, unpublish, subscribe } from 'Util/pubsub';
import { FontTracking } from 'App/mtiFontTracking/mtiFontTrackingCode';
import { VideoLoad } from 'App/video/load'; 
//import { YouTube } from 'App/video/youtube';
//jquerymigrate - removed

// From detail.js
import { Social } from 'App/social/share';
import 'tablescroll';
import 'expandable';
import 'tabs';
import { Form } from 'App/form/form';
//import { ListingFilterSort } from 'App/listing/filter-sort';
import { TradeImages } from 'App/images/tradeimages';
import { Homepage } from 'App/pages/homepage';
//import { Listing } from 'App/listing/listing';
import { MenuDropdowns } from 'App/menu/dropdowns';
import { HeaderMap } from 'App/map/headermap';

//import { ExpandCollapse } from 'App/expand-collapse/expand-collapse';

import 'throttle';
import 'debounce';
import 'appendaround';


// Inits
UtilAjax.init();
LazyLoad.init();
Rotator.init();
PromotionRotator.init();
HeroVideo.init();
MapLoad.init();
MenuDropdowns.init();
FormAjax.init();
MediaQueries.init();
RteToggle.init();
MegaMenu.init();
HeaderMap.init();

//HOMEPAGE
if ($('.js-page-homepage').length) {
	Homepage.init();
}

// TOOLTIP
if ($('.js-tooltip__trigger').length) {
	Tooltip.init();
}

// COOKIE
if ($('.js-cookie-confirm').length) {
	Cookie.init();
}

// HERO GALLERY
if (document.querySelector('.js-hero-gallery')) {
	HeroGallery.init();
}

// LISTING FILTER
//if (document.querySelector('.js-filter')) {
//	Listing.init();
//}

let contentTypes = document.querySelector('.js-content-type')?.value;

import { PageLanding } from 'Page/landing';
import { PageListing } from 'Page/listing';
import { PageDetail } from 'Page/detail';

if (contentTypes) {
	if (contentTypes.indexOf('detail') !== -1) {
		// Load detail JS
		PageDetail.init();
	}

	if (contentTypes.indexOf('listing') !== -1) {
		// Load listing JS
		PageListing.init();
	}

	if (contentTypes.indexOf('landing') !== -1) {
		// Load landing JS
		PageLanding.init();
	}
}

if (document.querySelector('.js-filtered-pods')) {
	filteredPods.init();
}

//EXPANDABLE
if (document.querySelector('.js-expandable')) {
	$('.js-expandable').expandable();
}

if (document.querySelector('.js-imglib-container')) {
	TradeImages.init();
}

var $slides = $('.js-rotator-slide');
var $slideLinks = $slides.find('a.btn');


// FILTER SORTED ITEMS
//if (document.querySelector('.js-filter__items')) {
//	ListingFilterSort.init();
//}


// SOCIAL
if (document.querySelector('.js-social-popup') || document.querySelector('.js-social-print')) {
	Social.init();
}

// VIDEO
if (document.querySelector('.js-video')) {
	VideoLoad.init();
}

// Mimics the same behaviour as phatfingaz (which uses a .js-hot trigger)
// but we can't add that class to rotator slides as it will prevent
// the other links from working when they appear at larger screen sizes
function processSlideClick(event) {
	event.preventDefault();
	var $link = $(this).find('a.btn');
	window.location = $link[0].href;
}

// Add an event listener for clicks on the $activities container on smaller screens
// Remove event listend on larger screens
if ($slideLinks.length) {
	MediaQueries.register([
		{
			queries: MediaQueries.queries['below-large-mobile'],
			shouldDegrade: false,
			match: function () {
				$slides.on('click', processSlideClick);
			},
			unmatch: function () {
				$slides.off('click', processSlideClick);
			}
		}
	]);
}

// Single Lightbox
if (document.querySelector('.js-lightbox-single')) {

	$('.js-lightbox-single').magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image',
		image: {
			titleSrc: function (item) {
				return item.el.attr('title');
			}
		}
	});
}

// Shuttlerock Image Lightbox
if (document.querySelector('.js-lightbox-single-shuttlerock')) {

	$('.js-lightbox-single-shuttlerock').magnificPopup({
		delegate: '.js-lightbox-shuttlerock-image',
		type: 'image',
		mainClass: 'mfp-shuttlerock',
		image: {
			titleSrc: function (item) {
				var avatar = item.el.data('shuttlerockAvatar');
				var userTitle = item.el.data('shuttlerockName');
				var returnBlock = '';
				if (userTitle.length) { returnBlock += '<span class="shuttlerock-name">' + userTitle + '</span><br/>'; }
				if (avatar.length) { returnBlock += '<img class="shuttlerock-avatar" src="' + avatar + '" alt=""/><br/>'; }
				returnBlock += item.el.attr('title');
				return returnBlock;
			}
		}
	});
}
//video popup
if (document.querySelector('.js-lightbox-video')) {

	$('.js-lightbox-video')
		.each(function () {
			$(this).magnificPopup({
				type: 'iframe',
				mainClass: 'mfp-video-pod',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false
			});
		});
}
if (document.querySelector('.js-lightbox-gallery')) {
	$('.js-lightbox-gallery').each(function () {
		$(this).magnificPopup({
			delegate: '.js-lightbox-image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			}

		});
	});
}


if (document.querySelector('.js-lightbox-shuttlerock-gallery')) {
	$('.js-lightbox-shuttlerock-gallery').each(function () {
		$(this).magnificPopup({
			delegate: '.js-lightbox-shuttlerock-image',
			type: 'image',
			mainClass: 'mfp-shuttlerock',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				titleSrc: function (item) {
					var avatar = item.el.data('shuttlerockAvatar');
					var userTitle = item.el.data('shuttlerockName');
					var returnBlock = '';
					if (userTitle.length) { returnBlock += '<span class="shuttlerock-name">' + userTitle + '</span><br/>'; }
					if (avatar.length) { returnBlock += '<img class="shuttlerock-avatar" src="' + avatar + '" alt=""/><br/>'; }
					returnBlock += '<span class="shuttlerock-byline">' + item.el.attr('title') + '</span>';
					return returnBlock;
				}
			}

		});
	});

}

// Social Pod source link
$('.js-social-source').on('click', function (event) {
	event.preventDefault();
	event.stopPropagation();
	var $source = $(this);
	var sourceLink = $source.attr('data-href');
	var target = $source.attr('data-target');

	if (target === '_blank') {
		window.open(sourceLink, 'Social Link');
	} else {
		location.href = sourceLink;
	}
});

$(document).on('click', '.js-hero-arrow', function () {
	var $content = $('#content');

	if ($content.length) {
		Core._scrollToElmSmart($content, -2, 500, false);
	}
});

if (window.location.hash !== '') {
	var $elm = $(window.location.hash);
	Core._scrollToElmSmart($elm, -2, 500, false);
}

$('.js-footer-accordion-toggler').on('click', function () {
	$(this).toggleClass('is-open');
	$(this).next('.js-footer-accordion').slideToggle();
});