import jQuery from 'jquery';

var selectors = Object.freeze({
	wrapper: '.js-filtered-pods',
	button: '.js-filtered-pods__filter-button',
	item: '.js-filtered-pods__item',
});

var dataAttributes = Object.freeze({
	filterValues: 'data-filtered-pods-values',
});

/**
	* Enum for filter button states.
	* @enum {boolean}
	*/
var ButtonState = Object.freeze({
	ON: true,
	OFF: false,
});

/**
	* Enum for filtered item states.
	* @type {boolean}
	*/
var ItemState = Object.freeze({
	VISIBLE: true,
	HIDDEN: false,
});


var filteredPods = {

	/**
	 * A set of filterOption IDs to use to filter visible pods.
	 * @typedef {string[]} Filter
	 */

	/**
	 * Initialise the Filtered Pods module.
	 */
	init: function () {
		filteredPods._initEvents();
	},

	/**
	 * Bind event listeners for the Filtered Pods module.
	 */
	_initEvents: function () {
		var i;
		var $el;

		var $buttons = document.querySelectorAll(selectors.button);
		for (i = 0; i < $buttons.length; i++) {
			$el = $buttons[i];

			$el.addEventListener('click', filteredPods._filterButtonClickEvent);
		}
	},

	/**
	 * Apply (or remove) the relevant filter.
	 *
	 * @param  {ClickEvent} e
	 */
	_filterButtonClickEvent: function (e) {
		var $button = e.target.closest(selectors.button);

		filteredPods._toggleButtonState($button);

		var $wrapper = $button.closest(selectors.wrapper);
		var filter = filteredPods._buildFilter($wrapper);
		filteredPods._applyFilter($wrapper, filter);
	},

	/**
	 * Swaps a filter button between on and off states.
	 *
	 * @param  {HTMLElement} $button - The button to toggle
	 */
	_toggleButtonState: function($button) {
		var state = filteredPods._getButtonState($button);

		/** @type {ButtonState} */
		var newState = ButtonState.ON;

		if (state === ButtonState.ON) {
			newState = ButtonState.OFF;
		}

		filteredPods._setButtonState($button, newState);
	},

	/**
	 * Gets the state of a given filter button.
	 *
	 * @param  {HTMLElement} $button
	 *
	 * @return {ButtonState}
	 */
	_getButtonState: function ($button) {
		if ($button.getAttribute('aria-pressed') === 'true') {
			return ButtonState.ON;
		} else {
			return ButtonState.OFF;
		}
	},

	/**
	 * Set the state of a given filter button.
	 *
	 * @param {HTMLElement} $button
	 * @param {ButtonState} state [description]
	 */
	_setButtonState: function ($button, state) {
		var currentState = filteredPods._getButtonState($button);

		if (state === currentState) {
			return;
		}

		switch (state) {
			case ButtonState.OFF:
				$button.setAttribute('aria-pressed', 'false');
				break;
			case ButtonState.ON:
			default:
				$button.setAttribute('aria-pressed', 'true');
				break;
		}
	},

	/**
	 * Build a Filter by looking at the values of the buttons that are currently on.
	 *
	 * @param  {HTMLElement} $wrapper - The wrapper for the control to be filtered.
	 *
	 * @return {Filter}
	 */
	_buildFilter: function ($wrapper) {
		var $buttons = $wrapper.querySelectorAll(selectors.button);

		var $filterButtons = Array.prototype.filter.call($buttons, filteredPods._isButtonOn);

		var filter = $filterButtons.map(filteredPods._getButtonValue);

		return filter;
	},

	/**
	 * For use with Array.prototype.filter. Checks if a button is on.
	 *
	 * @param  {HTMLElement} $button
	 *
	 * @return {boolean}
	 */
	_isButtonOn: function ($button) {
		return filteredPods._getButtonState($button) === ButtonState.ON;
	},

	/**
	 * For use with Array.prototype.map. Returns the value of a filter
	 *   button.
	 *
	 * @param  {HTMLElement} $button
	 *
	 * @return {string} - The value of the filter button.
	 */
	_getButtonValue: function($button) {
		var value = $button.getAttribute('value');

		return value;
	},

	/**
	 * Adjust visibility of filtered items so only those that match every value applied in the filter are visible.
	 *
	 * @param  {HTMLElement} $wrapper - The wrapper for the control to be filtered.
	 * @param  {Filter} filter - The filter to apply.
	 */
	_applyFilter: function ($wrapper, filter) {
		var $items = $wrapper.querySelectorAll(selectors.item);
		var applyFilterToItem = filteredPods._createFilterFunction(filter);

		var $hiddenItems = Array.prototype.filter.call($items, filteredPods._isItemHidden);
		var $itemsToShow = Array.prototype.filter.call($items, applyFilterToItem);

		var i;
		var $item;
		for (i = 0; i < $items.length; i++) {
			$item = $items[i];

			var isShowing = $hiddenItems.indexOf($item) === -1;
			var shouldShow = $itemsToShow.indexOf($item) !== -1;

			if (shouldShow) {
				if (!isShowing) {
					filteredPods._setItemState($item, ItemState.VISIBLE);
				}
			} else if (isShowing) {
				filteredPods._setItemState($item, ItemState.HIDDEN);
			}
		}
	},

	/**
	 * Creates a function that can apply a filter to an individual filtered item.
	 *
	 * @param  {Filter} filter - The filter that the function should apply.
	 *
	 * @return {($item: HTMLElement) => boolean} - A function that can be used with Array.prototype.filter.
	 */
	_createFilterFunction: function (filter) {
		return function ($item) {
			// Every item matches against an empty filter
			if (filter.length === 0) {
				return true;
			}

			var itemValues = $item.getAttribute(dataAttributes.filterValues).split(',');

			var i;
			var filterValue;

			// Match if the item matches *any* filter item
			for (i = 0; i < filter.length; i++) {
				filterValue = filter[i];

				if (itemValues.indexOf(filterValue) !== -1) {
					return true;
				}
			}
			return false;
		};
	},

	/**
	 * Set the visibility of a filtered item.
	 *
	 * @param {HTMLElement} $item - The filtered item to affect.
	 * @param {ItemState} state - The state to impose on the item.
	 */
	_setItemState: function ($item, state) {
		var currentState = filteredPods._getItemState($item);

		if (state === currentState) {
			return;
		}

		switch (state) {
			case ItemState.HIDDEN:
				$item.setAttribute('aria-hidden', 'true');
				break;
			case ItemState.VISIBLE:
			default:
				$item.setAttribute('aria-hidden', 'false');
				break;
		}
	},

	/**
	 * Query the visibility of a filtered item.
	 *
	 * @param  {HTMLElement} $item - The filtered item to check.
	 *
	 * @return {ItemState} - The state of the filtered item.
	 */
	 _getItemState: function ($item) {
		var isHidden = $item.getAttribute('aria-hidden') === 'true';

		if (isHidden) {
			return ItemState.HIDDEN;
		} else {
			return ItemState.VISIBLE;
		}
	},

	/**
	 * For use with Array.prototype.filter. Checks if a filtered item is hidden.
	 *
	 * @param  {HTMLElement} $item - The filtered item to check.
	 *
	 * @return {boolean} - If the filtered item is hidden.
	 */
	_isItemHidden: function($item) {
		return filteredPods._getItemState($item) === ItemState.HIDDEN;
	}
}


export { filteredPods };