/* app/ui/rte-toggle/rte-toggle */

import $ from 'jquery';

var selectors = Object.freeze({
	container: '.js-rte-toggle',
	trigger: '.js-rte-toggle__trigger',
	target: '.js-rte-toggle__target',
	attribute: 'aria-hidden'
});

var module = {
	init: function () {
		var $toggles = $(selectors.container);
		if ($toggles.length > 0) {
			module._initEvents($toggles);
		}
	},

	_initEvents: function ($elms) {
		$elms.on('click', selectors.trigger, module._toggleDisplay);
	},

	_toggleDisplay: function (event) {
		event.preventDefault();
		var $targets = $(event.currentTarget).closest(selectors.container).find(selectors.target);
		for (var i = 0; i < $targets.length; i++) {
			var $target = $targets.eq(i);

			if ($target.is('[aria-hidden="true"]')) {
				$target.attr(selectors.attribute, 'false');
			} else if ($target.is('[aria-hidden="false"]')) {
				$target.attr(selectors.attribute, 'true');
			}
		}
	}
};

var RteToggle = {
	init: module.init
};

export { RteToggle };
