/* app/ui/map/map-load */
/*global google, homepageMarkerContent */
import $ from 'jquery';
import 'headerMap';
import { MapHeader } from 'App/map-header/map-header';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var apiKey = 'AIzaSyBYUdUeGzshtBAn9Uk3gYkmhYWu5QcNC0g';
var $homepageMap;

var MapLoad = {
	init: function () {
		if (document.querySelector('.header-map a')) {
			MapHeader.init();
		}

		$homepageMap = $('#js-homepage-map');
		subscribe('/map/loadApi', MapLoad._loadApi);

		if ($homepageMap.length) {
			MapLoad.loadApi({ mapType: 'homepage' });
		}
	},

	_loadApi: function (data) {
		$.getScript('https://www.google.com/jsapi', function () {
			window.google.load('maps', '3', {
				other_params: 'key=' + apiKey, callback: function () {

					if (data.mapType === 'homepage') {
						MapLoad._initHomepageMap();
						publish('/map/apiLoaded', { google: window.google });
					}
					else if (data.mapType === 'header') {
						publish('/headermap/apiLoaded', { google: window.google });
					}
				}
			});
		});
	},

	_initHomepageMap: function () {
		var centerCoords = { lat: -45.15, lng: 169.521054 };
		var zoom = 7;

		if ($('.theme-sie').length) {
			centerCoords = { lat: -46.721678, lng: 168.134898 };
			zoom = 9;
		}

		var map = new google.maps.Map(document.getElementById('js-homepage-map'), {
			center: centerCoords,
			zoom: zoom,
			scrollwheel: false,
			mapTypeControl: true,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP
			},
			streetViewControl: false,
			styles: [{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "#b3d1ff"
					}
				]
			}]
		});

		var markers = [];
		var infoWindows = [];
		var markerImg = {
			url: '/Content/images/marker.png',
			scaledSize: new google.maps.Size(24, 34),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(12, 34)
		};

		function attachInfoWindow(marker, content) {
			var infowindow = new google.maps.InfoWindow({
				content: content,
				maxWidth: 250
			});
			infoWindows.push(infowindow);
			marker.addListener('click', function () {
				for (var i = 0; i < infoWindows.length; i++) {
					infoWindows[i].close();
				}
				infowindow.open(marker.get('map'), marker);
				google.maps.event.addListener(map, 'click', function (event) {
					for (var i = 0; i < infoWindows.length; i++) {
						infoWindows[i].close();
					}
				});
			});
		}

		for (var i = 0; i < homepageMarkerContent.length; i++) {
			markers.push(new google.maps.Marker({
				map: map,
				draggable: false,
				position: {
					lat: homepageMarkerContent[i][0][0],
					lng: homepageMarkerContent[i][0][1]
				},
				title: homepageMarkerContent[i][1],
				icon: markerImg
			}));
			attachInfoWindow(markers[i], homepageMarkerContent[i][2]);
		}

		if ($('.theme-sie').length) {
			// SIE has hard-coded markers
			markers.push(new google.maps.Marker({
				map: map,
				draggable: false,
				position: {
					lat: -46.5912468,
					lng: 168.3240542
				},
				title: 'Bluff',
				icon: markerImg
			}));

			markers.push(new google.maps.Marker({
				map: map,
				draggable: false,
				position: {
					lat: -46.4012468,
					lng: 168.3440542
				},
				title: 'Invercargill',
				icon: markerImg
			}));

			markers.push(new google.maps.Marker({
				map: map,
				draggable: false,
				position: {
					lat: -46.8862468,
					lng: 168.1140542
				},
				title: 'Oban',
				icon: markerImg
			}));
		}
	}
};

export { MapLoad };
