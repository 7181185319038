/* app/page/detail */
import $ from 'jquery';
import { VideoLoad } from 'App/video/load';
import 'tablescroll';
import 'magnificPopup';
import 'expandable';
import 'tabs';

// Check DOM for elements requiring JS
var $tables = $('.body--content').find('table');

var module = {
	init: function () {
		// Share Icons
		//Social.init();

		// Video Macros
		VideoLoad.init();
		// Table Scroll
		if ($tables.length) {
			$tables.tableScroll();
		}

		$('.js-expandable').expandable();

		if ($('.js-lightbox-gallery').length) {
			module._lightboxGallery();
		}

		if ($('.js-lightbox-shuttlerock-gallery').length) {
			module._lightboxShuttlerockGallery();
		}
	},

	_lightboxGallery: function () {
		$('.js-lightbox-gallery').each(function () {
			$(this).magnificPopup({
				delegate: '.js-lightbox-image',
				type: 'image',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
				},
				image: {
					titleSrc: function (item) {
						return item.el.attr('title');
					}
				}

			});
		});
	},

	_lightboxShuttlerockGallery: function () {
		$('.js-lightbox-shuttlerock-gallery').each(function () {
			$(this).magnificPopup({
				delegate: '.js-lightbox-shuttlerock-image',
				type: 'image',
				mainClass: 'mfp-shuttlerock',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
				},
				image: {
					titleSrc: function (item) {
						var avatar = item.el.data('shuttlerockAvatar');
						var userTitle = item.el.data('shuttlerockName');
						var returnBlock = '';
						if (userTitle.length) { returnBlock += '<span class="shuttlerock-name">' + userTitle + '</span><br/>'; }
						if (avatar.length) { returnBlock += '<img class="shuttlerock-avatar" src="' + avatar + '" alt=""/><br/>'; }
						returnBlock += '<span class="shuttlerock-byline">' + item.el.attr('title') + '</span>';
						return returnBlock;
					}
				}

			});
		});
	}
}

export { module as PageDetail };
