/* app/page/listing */
import $ from 'jquery';
import { ListingFilter } from 'App/listing/filter';
import { ListingFilterExpand } from 'App/listing/filter-expand';
import 'expandable';
import 'fakeSelect';

'use strict';

var $filter = $('.js-filter');

var module = {

	init: function () {
		if ($filter.length) {
			ListingFilter.init($filter);
			ListingFilterExpand.init($filter);
		}

		$('.js-fake__select').fakeSelect();
	}
}

export { module as PageListing }