import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var module = {

	init: function () {
		module._initEvents();
	},

	_initEvents: function () {
		$(document).on('submit', '.js-form-ajax', module._processSubmit);
	},

	_processSubmit: function (e) {
		e.preventDefault();

		var $form = $(e.target).closest('.js-form-ajax'),
			formData = $form.serialize(),
			action = $form.attr('action'),

			$error = $form.find($form.data('error'));

		publish('/form/ajax/begin', { containerId: 'result' });
		$error.html('');

		ajax({
			data: formData,
			url: action,
			method: 'POST',
			success: module._submitSuccess.bind($form),
			error: module._submitFailure.bind($form)
		});
	},

	_submitSuccess: function (response) {
		var $form = this,
			$output = $form.find($form.data('output'));

		publish('/track/analytics/event', {
			category: $form.data('track-category'),
			action: $form.data('track-action'),
			label: location.pathname
		});

		$output.html(response);
	},

	_submitFailure: function (response) {

		var $form = this,
			$output = $form.find($form.data('error'));

		$output.html(response.responseText);
	},
};

var FormAjax = {
	init: module.init
};

export { FormAjax };

