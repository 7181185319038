import $ from 'jquery';
import jQuery from 'jquery';
import { subscribe } from 'Util/pubsub';
import 'lazyscroll';
import 'jquerylazyload';
import { actual } from 'actual';

var contentClass = '.js-lazy-content';
var imagesClass = '.js-lazy-auto';
var $lazyLoadContent;
var $lazyLoadImages;
var widthPattern = /width=[0-9]*/;
var heightPattern = /height=[0-9]*/;
var roundTo = 100;

var resizeTimer;
var lazyLoadDataAttr = "original";

var module = {

	init: function () {

		$lazyLoadContent = $(contentClass);
		$lazyLoadImages = $(imagesClass);

		if ($lazyLoadImages.length) {
			module._updateImageWidth($lazyLoadImages);
			module._loadImages($lazyLoadImages);
		}
		if ($lazyLoadContent.length) {
			module._loadContent();
		}

		module._initSubscriptions();
	},

	_initLazyFunction: function (config) {
		config.elems.lazyScroll({
			callback: config.callback
		});
	},

	_initSubscriptions: function () {
		subscribe('/lazyload/image', module._loadImages);
		subscribe('/lazyload/ajax', module._loadAjaxedImages);
		$(window).on('resize /lazyload/resize', module._processResize);
	},

	_loadAjaxedImages: function (data) {

		if (data.html) {
			module._loadImagesInHtml(data.html);
		}
		module._loadImages(data.images);
	},

	_loadContent: function ($html) {
		$html = $html instanceof jQuery ? $html : $($.trim($html));
		if ($html.length) {
			var $content = $html.find(contentClass);
			module._content($content);
			return;
		}
		module._content($lazyLoadContent);
	},

	_updateImageWidth: function ($images) {
		$images.each(function () {
			var $image = $(this);
			var src = $image.data('original');
			if (src === "" || $image.closest('.rotator').length) {
				return;
			}
			var container = $image.closest('.figure');
			//fix if .figure is not used. use parent element.
			if (!container.length) {
				container = $image.parent();
			}
			var containerWidth = container.width();
			if (containerWidth === 0) {
				containerWidth = container.actual('width');
			}
			if (containerWidth === 0 || container.is(':not(:visible)')) {
				return;
			}

			// If an image is restrained by the height of its container, and
			// is wider than its container, use the width of the <img> element
			if ((container.height() >= $image.height()) && (containerWidth < $image.width())) {
				containerWidth = $image.width();
			}

			if (container.is('[data-sizes]')) {
				containerWidth = module._getBestWidth(containerWidth, container.data('sizes'));
			}

			var pixelRatio = Math.min(window.devicePixelRatio || 1, 2);
			//need to round width to reduce 1px image generation variations on server
			var widthRounded = Math.ceil(pixelRatio * containerWidth / roundTo) * roundTo;

			var oldWidth = $image.data('newWidth');

			if (!oldWidth || oldWidth < widthRounded) {
				src = module._changeWidth(src, widthRounded);
				$image.data('original', src);
				$image.data('newWidth', widthRounded);
			}
		});
	},

	_changeWidth: function (src, width) {
		var ratio = module._getRatio(src);
		if (widthPattern.test(src)) {
			src = src.replace(widthPattern, 'width=' + width);
		} else if (src.indexOf('?') !== -1) {
			src += '&width=' + width;
		} else {
			src += '?width=' + width;
		}

		if (ratio) {
			//var height = Math.round(width * ratio);
			if (heightPattern.test(src)) {
				src = src.replace(heightPattern, 'heightratio=' + ratio);
			} else {
				src += '&heightratio=' + ratio;
			}
		}
		return src;
	},

	_getRatio: function (src) {
		var ratio = 0;
		var data = {};
		if (src.indexOf('?') !== -1) {
			var querys = src.split('?')[1].split('&');
			for (var i = 0; i < querys.length; i++) {
				var keyValue = querys[i].split('=');
				data[keyValue[0]] = keyValue[1];
			}
		}

		if (data.height && data.width) {
			ratio = parseInt(data.height, 10) / parseInt(data.width, 10);
		}

		return ratio;
	},

	_getBestWidth: function (containerWidth, sizeData) {
		var sizes = sizeData.match(/[0-9]+/g);
		sizes = $.map(sizes, function (num) { return parseInt(num.toString(), 10); });
		sizes.sort(function (a, b) { return a - b; });

		if (sizes.length) {
			var bestSize = sizes[sizes.length - 1];
			for (var i = sizes.length - 2; i >= 0; i--) {
				if (sizes[i] >= containerWidth) {
					bestSize = sizes[i];
				} else {
					break;
				}
			}
			return bestSize;
		}
		return containerWidth;
	},

	_processResize: function () {
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		}
		resizeTimer = setTimeout(function () {
			if ($lazyLoadImages.length) {
				module._updateImageWidth($lazyLoadImages);
				module._getImages($lazyLoadImages, true);
			}
		}, 1500);
	},

	_loadImages: function ($images) {
		module._getImages($images);
	},

	_loadImagesInHtml: function ($html) {
		$html = $html instanceof jQuery ? $html : $($.trim($html));
		if ($html.length) {
			var $images = $html.find(imagesClass);
			if (!$images.length) {
				return;
			}
			$lazyLoadImages = $lazyLoadImages.add($images);
			module._updateImageWidth($images);
			module._getImages($images);
			return;
		}
		module._getImages($lazyLoadImages);
	},

	_getImages: function ($images, noAnimation) {
		var effect = !noAnimation && !$('.oldie').length ? 'fadeIn' : 'show';

		if (!$images || !$images.length) {
			return;
		}

		var $lazyImages = $images.filter(function () {
			var $thisImg = $(this);

			return this.src.indexOf($thisImg.data('original')) === -1;
		});

		if ($lazyImages.length) {
			$lazyImages.lazyload({
				effect: effect,
				failure_limit: 99999,
				threshold: 300,
				data_attribute: lazyLoadDataAttr,
			});
		}
	},

	_content: function ($elms) {
		$elms.lazyScroll({
			callback: function () {
				this.$element.ajaxInclude();
			}
		});
	}
};

export { module as LazyLoad };