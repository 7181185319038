import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var selectors = Object.freeze({
	gallery: '.js-hero-gallery',
	item: '.js-hero-gallery__item',
	image: '.js-hero-gallery__item',
	button: '.js-hero-gallery__button',
});

var dataAttributes = Object.freeze({
	index: 'data-hero-gallery-index',
});

var events = Object.freeze({
	lazyload: '/lazyload/image',
});


var module = {
	/**
	 * Initialise the hero gallery module.
	 */
	init: function () {
		module._initEvents();
	},

	/**
	 * Bind event listeners for the hero gallery module.
	 */
	_initEvents: function () {
		var i;
		var $el;

		var $buttons = document.querySelectorAll(selectors.button);

		for (i = 0; i < $buttons.length; i++) {
			$el = $buttons[i];
			$el.addEventListener('click', module._buttonClickEvent);
		}
	},

	/**
	 * Select the relevant item when a button is clicked.
	 *
	 * @param  {Event} e
	 */
	_buttonClickEvent: function (e) {
		/** @type {HTMLElement} */
		var $button = e.target.closest(selectors.button);

		var $gallery = $button.closest(selectors.gallery);

		var buttonIndex = $button.getAttribute(dataAttributes.index);
		var $matchingItem = module._getItemByIndex($gallery, buttonIndex);

		if ($matchingItem) {
			module._selectItem($matchingItem);
		}
	},

	/**
	 * Gets the hero gallery item with a given index.
	 *
	 * @param  {HTMLElement} $gallery - The gallery containing the items to check.
	 * @param  {number} index - The index of the item to get.
	 *
	 * @return {HTMLElement | null} - The item at the given index
	 */
	_getItemByIndex: function ($gallery, index) {
		var $items = $gallery.querySelectorAll(selectors.item);

		var i;
		var $item;
		for (i = 0; i < $items.length; i++) {
			$item = $items[i];

			if ($item.getAttribute(dataAttributes.index) === index) {
				return $item;
			}
		}

		return null;
	},

	/**
	 * Gets the hero gallery button with a given index.
	 *
	 * @param  {HTMLElement} $gallery - The gallery containing the buttons to check.
	 * @param  {number} index - The index of the button to get.
	 *
	 * @return {HTMLElement | null} - The button at the given index
	 */
	_getButtonByIndex: function ($gallery, index) {
		var $buttons = $gallery.querySelectorAll(selectors.button);

		var i;
		var $button;
		for (i = 0; i < $buttons.length; i++) {
			$button = $buttons[i];

			if ($button.getAttribute(dataAttributes.index) === index) {
				return $button;
			}
		}

		return null;
	},

	/**
	 * Select a given gallery item.
	 *
	 * @param  {HTMLElement} $item - The item to be selected.
	 * @param  {HTMLElement} $button - The button matching the item to be selected.
	 */
	_selectItem: function ($item) {
		var $gallery = $item.closest(selectors.gallery);

		var itemIndex = $item.getAttribute(dataAttributes.index);
		var $button = module._getButtonByIndex($gallery, itemIndex);

		var $selectedItem = module._getSelectedItem($gallery);

		var selectedItemIndex = $selectedItem.getAttribute(dataAttributes.index);
		var $selectedButton = module._getButtonByIndex($gallery, selectedItemIndex);

		if ($selectedItem === $item) {
			return;
		}

		if ($selectedItem) {
			$selectedItem.setAttribute('aria-hidden', 'true');
		}
		if ($selectedButton) {
			$selectedButton.setAttribute('aria-current', 'false');
		}

		$item.setAttribute('aria-hidden', 'false');
		$button.setAttribute('aria-current', 'true');

		// Using jQuery because Lazyload code is
		var $images = $($gallery).find(selectors.image);
		//publish(events.lazyload, [$images]);
	},

	/**
	 * Get the currently selected item for a given gallery. Assumes only one item is selected at a time.
	 *
	 * @param  {HTMLElement} $gallery - The gallery to check.
	 *
	 * @return {HTMLElement | null} - The selected item, if there is one.
	 */
	_getSelectedItem: function ($gallery) {
		var $items = $gallery.querySelectorAll(selectors.item);

		var i;
		var $item;
		for (i = 0; i < $items.length; i++) {
			$item = $items[i];

			if (module._isSelected($item)) {
				return $item;
			}
		}

		return null;
	},

	/**
	 * Check if the given item is selected.
	 *
	 * @param  {HTMLElement} $item - The item to check.
	 *
	 * @return {boolean} - If the given item is selected.
	 */
	_isSelected: function ($item) {
		var isSelected = $item.getAttribute('aria-hidden') !== 'true';

		return isSelected;
	},
};

var HeroGallery = {
	init: module.init
};

export { HeroGallery };



