/* app/page/tradeimages */
import $ from 'jquery';
import 'magnificPopup';
import { ImageLibrary } from 'App/images/imagelibrary';

var TradeImages = {

	init: function () {
		TradeImages._initTradeImages();
	},

	_initTradeImages: function () {
		$('.js-imglib-container').magnificPopup({
			delegate: '.js-lightbox-image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			}
		});
	},
};

export { TradeImages };

