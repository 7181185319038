/* app/page/form */
import $ from 'jquery';
import { Validate } from 'App/form/validate';
import 'unobstructive';

var Form = {
	init: function () {
		//FormValidate.init();
		Validate.init();
	}
}

export { Form };