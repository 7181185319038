/* app/ui/listing/filter-status */

import $ from 'jquery';

'use strict';

var $statusList;

var module = {
	init: function ($elm) {
		$statusList = $elm;
		module._initEvents();
	},

	// Listen for history popstate event
	_initEvents: function () {
		$(window).on('popstate', module._restoreFilterStatus);
	},

	// Clear all filters
	// Clear active filters array
	// Get object of query string key-value pairs
	// Populate the activeFilters array
	// Loop through activeFilters and check 
	_restoreFilterStatus: function () {
		var activeFilters = [];
		$('.js-filter__field input').prop('checked', false);

		if (window.location.search !== '') {
			var query = module._getQueryStringObject();
			for (var key in query) {
				if (query.hasOwnProperty(key)) {
					var value = query[key].split(',');
					activeFilters.push([key, value]);
				}
			}
		} else {
			$statusList.empty().parent().addClass('hidden');
		}

		if (activeFilters.length > 0) {
			var filterListing = '';

			for (var i = 0; i < activeFilters.length; i++) {

				var filter = activeFilters[i];

				for (var j = 0; j < filter[1].length; j++) {
					var fieldName = filter[0];
					var filterValue = filter[1][j];
					var $input = $('input[value=' + filterValue + ']').prop('checked', true);
					var displayText = $input.attr('data-status-value');
					var item = module._createStatusItem(fieldName, filterValue, displayText);

					filterListing += item;
				}
			}
			$statusList.html(filterListing).parent().removeClass('hidden');
		}
	},

	// Split query string into fields
	// Loop through fields and split into key/value pairs
	// Only add to query object if its not a pagination field
	// Returns object of query string key/value pairs
	_getQueryStringObject: function () {
		var queryString = document.location.search.substring(1).split('&');
		var query = {};

		for (var i = 0; i < queryString.length; i++) {
			var fieldArray = queryString[i].split('=');

			if (fieldArray[0] !== 'page') {
				query[decodeURIComponent(fieldArray[0])] = decodeURIComponent(fieldArray[1]);
			}
		}
		return query;
	},

	// Return the HTML for a filter status item
	_createStatusItem: function (filterName, filterValue, displayText) {
		return '<span class="js-filter__list-item filter--list-item" data-filter-name="' + filterName + '" data-filter-value="' + filterValue + '">' + displayText + '<span class="iconf iconf-close"></span></span>';
	}
};

export { module as ListingFilterStatus };