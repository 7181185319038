import $ from 'jquery';
import { publish, subscribe } from 'Util/pubsub';

var cache = {};

var module = {
	// Init called on all.js so subscribed from the get go
	init: function () {
		subscribe('/ajax/get', $.proxy(module._processRequest, module));
	},

	_processRequest: function (data) {

		var niceUrl = data.niceUrl || null;
		var callback = module._generateCallback(data.url, niceUrl, data.id, data.callback);
		module._getContent(data.url, niceUrl, data.id, callback);
	},

	_generateCallback: function (url, niceUrl, id, callback) {

		if (!callback) {
			return function (response) {
				cache[url] = { content: response };
				module._publishResponseEvent(id, response, niceUrl);
			};
		} else {
			return function (response) {
				module._publishResponseEvent(id, response, niceUrl);
				callback(response);
			};
		}
	},

	_getContent: function (ajaxUrl, niceUrl, id, callback) {
		if (cache[ajaxUrl] !== undefined) {
			var response = cache[ajaxUrl].content;
			module._publishResponseEvent(id, response, niceUrl);
		} else {
			$.ajax({
				url: ajaxUrl,
				timeout: 15000,
				success: callback,
				fail: function () {
					if (niceUrl !== null) {
						window.location = niceUrl;
					}
				}
			});
		}
	},

	_publishResponseEvent: function (id, response, niceUrl) {
		publish('/ajax/ready/' + id, [{ html: response, niceUrl: niceUrl }]);
	}
};

export { module as UtilAjax }