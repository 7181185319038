/* app/ui/rotator/promotion-rotator */
import $ from 'jquery';
import jQuery from 'jquery';
import 'slick';
import { MediaQueries } from 'Util/mediaqueries';

var selectors = {
	rotatorMobile: '.js-rotator-mobile',
	rotatorAll: '.js-rotator-slick'
};

var dataSelectors = {
	type: 'rotator-type',
	auto: 'autorotate'
};

var RotatorTypes = {
	promos: 'promos',
	ksp: 'keySellingPoints',
	highlights: 'contentHighlights',
	carousel: 'carousel',
	hero: 'hero',
	iconCarousel: 'icon-carousel',
	reviews: 'review-carousel',
};


var module = {
	init: function () {
		module._initBreakpoints();

		module._initSlick(selectors.rotatorAll)();
	},

	_initBreakpoints: function () {
		MediaQueries.register([
			{
				queries: MediaQueries.queries['below-tablet-landscape'],
				shouldDegrade: false,
				match: module._initSlick(selectors.rotatorMobile),
				unmatch: module._uninitSlick(selectors.rotatorMobile)
			}
		]);
	},

	_initSlick: function (selector) {
		return function () {
			var $rotators = $(selector);

			$rotators.each(module._initRotator);
		}
	},

	_initRotator: function (i, rotator) {
		var $rotator = $(rotator);

		var settings = module._getSettings($rotator);

		$rotator.slick(settings);
		$rotator.on('beforeChange', module._onBeforeChange);
	},

	_getSettings: function ($rotator) {
		var settings;
		var type = $rotator.data(dataSelectors.type);

		switch (type) {
			case RotatorTypes.hero:
				settings = {
					dots: false,
					infinite: true,
					slidesToShow: 1,

					responsive: [
						{
							breakpoint: 1023,
							settings: {
								arrows: false,
								dots: true
							}
						}
					]
				};
				break;
			case RotatorTypes.carousel:
				settings = {
					dots: false,
					infinite: true,
					slidesToShow: 3,
					responsive: [
						{
							breakpoint: 567,
							settings: {
								slidesToShow: 1,
								dots: true,
								arrows: false
							}
						},
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1
							}
						},
						{
							breakpoint: 1023,
							settings: {
								slidesToShow: 2
							}
						}
					]
				};
				break;
			case RotatorTypes.highlights:
				settings = {
					dots: false,
					slidesToShow: 1,
					autoplay: $rotator.data(dataSelectors.auto) === 'True' ? true : false,
					autoplaySpeed: 7000,
					responsive: [
						{
							breakpoint: 767,
							settings: {
								dots: true,
								arrows: false
							}
						}
					]
				};
				break;
			case RotatorTypes.ksp:
				settings = {
					dots: true,

					slidesToShow: 2,
					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1
							}
						}
					]
				};
				break;
			case RotatorTypes.iconCarousel:
				settings = {
					slidesToShow: 4,
					dots: false,
					responsive: [
						{
							breakpoint: 1023,
							settings: {
								slidesToShow: 3
							}
						},
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 479,
							settings: {
								slidesToShow: 1
							}
						},
					],
				};
				break;
			case RotatorTypes.reviews:
				settings = {
					slidesToShow: 1,
					dots: false,
					arrows: true,

					responsive: [
						{
							breakpoint: 767,
							settings: {
								dots: true,
								arrows: false,
							},
						},
					],
				};
				break;
			case RotatorTypes.promos:
			default:
				settings = {
					infinite: true,
					dots: true,

					slidesToShow: 2,
					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1
							}
						}
					]
				};
				break;
		}

		return settings;
	},

	// Runs before the next slide is shown
	// Check if image source is correct, if not - lazyload it
	_onBeforeChange: function (event, slick, currentslide, nextSlide) {
		var $nextSlide = slick.$slides.eq(nextSlide);

		if ($nextSlide.find('img[data-original]').length && !module._isImageLoaded($nextSlide)) {
			module._processImg($nextSlide);
		}
	},


	// Process image lazyloading
	// Get the actual image source and overwrite the 'src' attribute
	_processImg: function ($nextSlide) {
		var $nextImg = $nextSlide.find('img');
		$.each($nextImg, function (index, item) {
			var nextImgSrc = $(item).data('original');
			$(item)[0].src = nextImgSrc;
		});
	},

	// Return true if the image 'src' attribute matches the 'data-original' attribute
	_isImageLoaded: function ($nextSlide) {
		var $nextImage = $nextSlide.find('img');
		return $nextImage[0].src.indexOf($nextImage.data('original')) !== -1;
	},

	_uninitSlick: function (selector) {
		return function () {
			var $rotators = $(selector);

			$rotators.slick('unslick');
			$rotators.off('beforeChange', module._onBeforeChange);
		};
	}
};

var PromotionRotator = {
	init: module.init
};

export { PromotionRotator };