import $ from 'jquery';
import jQuery from 'jquery';

// PureCSS's ui.js
// Modified for Require and for RJ

// v1.0
(function ($) {

	var menu = document.getElementById('menu');
	var menuLink = document.getElementById('main-nav-toggle');
	var body = document.body;
	function toggleClass(element, className) {
		var classes = element.className.split(/\s+/),
			length = classes.length,
			i = 0;

		for (; i < length; i++) {
			if (classes[i] === className) {
				classes.splice(i, 1);
				break;
			}
		}
		// The className is not found
		if (length === classes.length) {
			classes.push(className);
		}

		element.className = classes.join(' ');
	}

	if (menuLink) {
		menuLink.onclick = function(e) {
			var active = 'active';
			var navShowing = 'nav-is-showing';

			e.preventDefault();
			toggleClass(menu, active);
			toggleClass(menuLink, active);
			toggleClass(body, navShowing);

		};
	}

})(jQuery, window, document);