import $ from 'jquery';
import jQuery from 'jquery';

var $header;
var $icons;
var $searchInput;
var selectors = {
	icon: '.js-header-icon',
	toggle: '.js-header-toggle'
};
var classes = {
	active: 'is-active',
	search: 'js-header-icon-search'
};

var $currentIcon, timeout;


var MenuDropdowns = {
	init: function () {
		$header = $('.js-header');
		$icons = $header.find(selectors.icon);
		$searchInput = $header.find('.js-header-search-input');

		MenuDropdowns._initEvents();
	},

	_initEvents: function () {
		$header.on('click', selectors.toggle, MenuDropdowns._processToggleClick);
		$icons.on('mouseleave', MenuDropdowns._processIconLeave);
		$icons.on('mouseenter', MenuDropdowns._cancelIconLeave);
	},

	_processToggleClick: function (event) {
		event.preventDefault();
		var $thisIcon = $(this).closest(selectors.icon);

		if ($thisIcon.length) {
			if ($thisIcon.hasClass(classes.active)) {
				$thisIcon.removeClass(classes.active);
			} else {
				$icons.removeClass(classes.active);
				$thisIcon.addClass(classes.active);

				if ($thisIcon.hasClass(classes.search)) {
					$searchInput.focus();
				}
			}
		}
	},

	_processIconLeave: function (event) {
		var $thisIcon = $(this);

		// Don't hide dropdown if search has focus
		if ($thisIcon.hasClass(classes.search) && $searchInput.is(':focus')) {
			return;
		}

		// Remove active class on mouseleave event
		if ($thisIcon.hasClass(classes.active)) {
			$currentIcon = $thisIcon;
			timeout = window.setTimeout(function () {
				$thisIcon.removeClass(classes.active);
			}, 500);
		}
	},

	_cancelIconLeave: function (event) {
		var $thisIcon = $(this);

		if ($thisIcon.is($currentIcon)) {
			window.clearTimeout(timeout);
			timeout = undefined;
		}
	}
};

export { MenuDropdowns };