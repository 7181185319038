// app/ui/mega-menu/mega-menu
import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';
import 'headerMap';

var $windowWidth;
var $hasChild;
var $hasChildLink;

var module = {
	init: function () {
		module._initElements();

		subscribe('/megamenu/close', module._closeMegaMenu);
		// Add click listener
		$hasChildLink.on('click', module._toggleMegaMenu);

		$('body').on('click',
			function (e) {
				var $target = $(e.target);

				if (!$target.closest('#menu').length) {
					module._closeMegaMenu();
				}
			}
		);
	},

	_initElements: function () {
		$windowWidth = $(window).width();
		$hasChild = $('.has-children');
		$hasChildLink = $('.has-children > a');
	},

	_toggleMegaMenu: function (event) {
		var $animatingEl;

		// Check if this is a grandchild menu
		if ($(this).parent().parents('.has-children').length !== 0) {
			if (windowWidth <= 1023) {
				event.preventDefault();
				$(this).parent().addClass('active');

				$animatingEl = $(this).parent();
				$animatingEl.addClass('is-animating');
				window.setTimeout(function () {
					$animatingEl.removeClass('is-animating');
				}, 500);

				// Add click listener to close button
				$('.has-children.active .close-button').click(function () {
					$(this).parent().parent().parent().removeClass('active');

					$animatingEl = $(this).parent().parent().parent();
					$animatingEl.addClass('is-animating');
					window.setTimeout(function () {
						$animatingEl.removeClass('is-animating');
					}, 500);

					$('.mega-menu-backdrop').removeClass('active');
				});
			} else {
				// do nothing;
				return;
			}
		} else {
			// Check that the map is open
			if ($('.js-location').hasClass('is-active')) {
				publish('/headerMap/toggle');
			}

			// Add the backdrop's "active" class
			$('.mega-menu-backdrop').addClass('active');
			// Close the menu if the original parent was clicked.
			if ($(this).parent().hasClass('active')) {
				$(this).parent().removeClass('active');

				$animatingEl = $(this).parent();
				$animatingEl.addClass('is-animating');
				window.setTimeout(function () {
					$animatingEl.removeClass('is-animating');
				}, 500);

				$('.mega-menu-backdrop').removeClass('active');
			} else {
				// Check if any other submenus are open
				// If there are, close them and open the clicked dropdown
				if ($hasChild.hasClass('active')) {
					$hasChild.removeClass('active');
					$(this).parent().addClass('active');

					$animatingEl = $(this).parent();
					$animatingEl.addClass('is-animating');
					window.setTimeout(function () {
						$animatingEl.removeClass('is-animating');
					}, 500);
				} else {
					// If there aren't, just open the new overlay.
					$(this).parent().addClass('active');

					$animatingEl = $(this).parent();
					$animatingEl.addClass('is-animating');
					window.setTimeout(function () {
						$animatingEl.removeClass('is-animating');
					}, 500);
				}

				// Add click listener to close button
				$('.has-children.active .close-button').on('click', function () {
					$(this).parent().parent().parent().removeClass('active');

					$animatingEl = $(this).parent().parent().parent();
					$animatingEl.addClass('is-animating');
					window.setTimeout(function () {
						$animatingEl.removeClass('is-animating');
					}, 500);

					$('.mega-menu-backdrop').removeClass('active');
				});
			}
		}
		return false;

	},

	_closeMegaMenu: function() {
		$hasChild.removeClass('active');

		var $animatingEl = $hasChild;
		$animatingEl.addClass('is-animating');
		window.setTimeout(function () {
			$animatingEl.removeClass('is-animating');
		}, 500);

		$('.mega-menu-backdrop').removeClass('active');
	},
}


var MegaMenu = {
	init: module.init
};

export { MegaMenu };
