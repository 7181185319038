import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var module = {
	init: function () {
		if ($('section.image-library').length >= 0) {
			l = localStorage;
			// P == Photo IDs
			p = [];

			module._stateloader();
			subscribe('/imagegallery/load', function () {
				stateloader();
			});


			// When clicking the add button...
			$(document).on('click', '.js-imglib-e .js-btn-add', function (event) {
				var storedPhotos = l.getItem('storedPhotos');
				p = [];
				if (storedPhotos) {
					p = storedPhotos.split(',');
				}
				// Grab the ID
				$parent = $(this).parents('.js-imglib-e');
				key = $parent.data('image-id').toString();

				// If the key doesn't exist...
				if (storedPhotos) {
					if (p.indexOf(key) === -1) {
						// Push the key into the keyring...
						p.push(key);
						// And store the keys.
						storeKeys();
						// Add the active class
						$parent.addClass('img-added');
					}
				} else {
					p.push(key);
					storeKeys();
					$parent.addClass('img-added');
				}
				module._confirmation();

			});

			// When clicking the remove button...
			$(document).on('click', '.js-imglib-e .js-btn-remove', function () {
				var storedPhotos = l.getItem('storedPhotos');
				if (storedPhotos) {
					p = storedPhotos.split(',');
					$parent = $(this).parents('.js-imglib-e');
					key = $parent.data('image-id').toString();
					// If the key exists...
					if (p.indexOf(key) !== -1) {
						// Grab the index...
						kl = p.indexOf(key);
						// Remove the key from the keyring...
						p.splice(kl, 1);
						// And store the keys.
						storeKeys();
						// Remove the active class.
						$parent.removeClass('img-added');
					}
					confirmation();
				}
			});

			$('.js-confirm-selection').click(function (event) {
				var storedPhotos = l.getItem('storedPhotos');
				p = storedPhotos.split(',');
				event.preventDefault();
				$('.js-imglib-container').toggleClass('form-active');
				$('.js-selection-widget').fadeOut();
				runAjax(p.toString());
			});
			$('.js-gallery-return').click(function () {
				$('.js-imglib-container').toggleClass('form-active');
				$('.js-selection-widget').fadeIn();
				stateloader();
				confirmation();
			});

		}
	},


	// Initialization
	_stateloader: function () {
		var storedPhotos = l.getItem('storedPhotos');
		if (storedPhotos) {
			// Grab the IDs from localstorage, and push them into the array
			p = storedPhotos.split(',');
			// Loop through the photo IDs, find the listings with the
			// appropriate ID, and toggle their active classes
			for (var ki in p) {
				if ($('.js-imglib-e').data('image-id') == p[ki]) {
					$('.js-imglib-e').removeClass('img-added');
				}
			}
			for (var k in p) {
				$('.js-imglib-e[data-image-id=' + p[k] + ']').addClass('img-added');
			}
		}
		module._confirmation();
		runAjax(storedPhotos);
	},

	_confirmation: function () {
		var storedPhotos = l.getItem('storedPhotos');
		p = [];
		if (storedPhotos) {
			p = storedPhotos.split(',');
		}
		$sidebarSel = $('.js-selection-widget');
		$sidebarSel.find('.counter').html(p.length);

		if (!storedPhotos) {
			if (!$sidebarSel.hasClass('no-selection')) {
				$sidebarSel.fadeOut().addClass('no-selection');
			} else {
				return;
			}
		} else {
			if ($sidebarSel.hasClass('no-selection')) {
				$sidebarSel.fadeIn().removeClass('no-selection');
			} else {
				return;
			}
		}
		p = $('#imageList').val(p);
	},

	_storeKeys: function () {
		storedPhotos = localStorage.getItem('storedPhotos');
		// Prep photo ids for storage
		ps = p.toString();
		if (storedPhotos) {
			// Remove the old key
			l.removeItem('storedPhotos');
		}
		// Store the new one
		l.setItem('storedPhotos', ps);
		$("#imageList").val(ps);
	},

	_runAjax: function (ids) {
		var nodeId = $('.js-node-id').val();
		$.ajax({
			url: "/listing/getlistings/" + nodeId + "/ImageLibrary?images=" + ids,
			dataType: "html",

			success: function (d) {
				$('.js-ajax-img-confirm').html(d);
				$('.js-ajax-img-confirm .js-imglib-e').addClass('img-added');

				window.setTimeout(function () {
					publish('/lazyload/image', [$('.js-imglib-form-wrapper .js-imglib-e img')]);
				}, 500);
			}
		});
	},
};


var ImageLibrary = {
	init: module.init
};

export { ImageLibrary };
