define(
	[
		'jquery'
	],
	function($) {
		var $sidebarControls = $('.js-sidebar-controls');
		var hideThis = function(el) {
			el.addClass('hidden');
		};
		$sidebarControls.children('.js-control').on('click', function() {
			var data = $(this).data('control');
			switch (data) {
			case 'showAll':
				$('.js-sub-navigation .hide').addClass('unhidden');
				hideThis($(this));
				$(this).siblings('.js-control[data-control="showLess"]').removeClass('hidden');
				break;
			case 'showLess':
				$('.js-sub-navigation .hide').removeClass('unhidden');
				$(this).addClass('hidden');
				hideThis($(this));
				$(this).siblings('.js-control[data-control="showAll"]').removeClass('hidden');
				break;
			}
		});
	}
);