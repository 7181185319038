import $ from 'jquery';

var $filter;
var $fields;
var activeClass = 'active';
var fieldSelector = '.js-filter__field';

var module = {
	init: function($elm) {
		$filter = $elm;
		$fields = $filter.find(fieldSelector);

		module._initEvents();
	},

	_initEvents: function() {
		$('body').on('click', module._processBodyClick);
		$filter.on('click', '.js-filter__toggle', module._processFieldClick);
		$fields.on('mouseleave', module._processFieldLeave);
	},

	_processBodyClick: function(event) {
		if (!$(event.target).closest(fieldSelector + '.' + activeClass).length) {
			$fields.removeClass(activeClass);
		}
	},

	_processFieldClick: function (event) {
		event.preventDefault();
		event.stopPropagation();
		var $filterField = $(this).closest(fieldSelector);

		if ($filterField.hasClass(activeClass)) {
			$filterField.removeClass(activeClass);
		} else {
			$fields.removeClass(activeClass);
			$filterField.addClass(activeClass);
		}
	},

	_processFieldLeave: function(event) {
		var $filterField = $(this);
		if ($filterField.hasClass(activeClass)) {
			$filterField.removeClass(activeClass);
		}
	}
};

export { module as ListingFilterExpand };