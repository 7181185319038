/* app/ui/video/hero-video */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';

var Module;
var $heroVideos;
var $heroCampignVideos;

var Module = {
	init: function () {
		$heroVideos = $('.js-hero-video') || null;
		$heroCampignVideos = $(".js-hero__campaign-video") || null;


		if ($heroVideos.length) {
			Module._initMediaQueries();
		}

		if ($heroCampignVideos.length) {
			Module._loadVideo();
		}
	},

	_initMediaQueries: function () {

		MediaQueries.register([
			{
				queries: MediaQueries.queries['hero-large'],
				shouldDegrade: true,
				match: Module._loadVideo
			}
		]);
	},

	_loadVideo: function () {
		for (var i = 0; i < $heroVideos.length; i++) {
			var $vid = $heroVideos.eq(i);
			var $sources = $vid.find('source');

			for (var j = 0; j < $sources.length; j++) {
				var $source = $sources.eq(j);
				var src = $source.data('src');

				if (src !== '') {
					$source.attr('src', src);
				}
			}

			$vid[0].load();
			$vid[0].play();
		}
	}
};

var HeroVideo = {
	init: Module.init
};

export { HeroVideo };