import $ from 'jquery';
import 'enquire';

var shouldDegrade;

var MediaQueries = {

	queries: {
		'below-small-mobile': 'screen and (max-width: 24em)',
		'small-mobile': 'screen and (min-width: 24em)',
		'below-large-mobile': 'screen and (max-width: 35.5em)',
		'large-mobile': 'screen and (min-width: 35.5em)',
		'all-mobile': 'screen and (max-width: 48em)',
		'tablet': 'screen and (min-width: 48em) and (max-width: 64em)',
		'tablet-plus': 'screen and (min-width: 48em)',
		'below-tablet-landscape': 'screen and (max-width: 1023px)',
		'desktop': 'screen and (min-width: 64em)',
		'below-desktop': 'screen and (max-width: 64em)',
		'desktop-large': 'screen and (min-width: 80em)',
		'large-calendar': 'screen and (max-width: 650px)',
		'hero-large': 'screen and (min-width: 700px)'
	},

	init: function () {
		//We only want to fire mediaqueries for mediaquery capable browsers. i.e. Not Old IE which gets a fixed view
		shouldDegrade = !$('.oldie').length;
	},

	register: function (config) {
		if (Object.prototype.toString.call(config) === '[object Array]') {
			for (var i = 0; i < config.length; i++) {
				var currentConfig = config[i];
				this._addToHandler(currentConfig);
			}
		} else {
			this._addToHandler(config);
		}

	},

	_addToHandler: function (config) {
		//Init JS mediaquery handlers using Enquire.JS
		enquire.register(config.queries, {
			match: config.match,
			unmatch: config.unmatch,
			deferSetup: true
		}, config.shouldDegrade).listen(250);
	}
};

export { MediaQueries };