/* app/ui/listing/filter-sort */

import $ from 'jquery';
import jQuery from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';
import 'mixItUp';

var historySupport = window.history && history.pushState;
var firstRun = false;
var $filterStatus;
var activeFilters = [];
var targetFilter;

var module = {
	init: function () {
		$filterStatus = $('.js-filter__status');

		module._initEvents();
		module._initMixItUp();
		subscribe('/mixitup/init', module._initMixItUp);

		if ($('.tab-content-current .pods').length) {
			module._restoreFromHistory();
		}
	},

	_initMixItUp: function () {
		var $pods = $('.pods');
		var runMixItUpInterval;

		if ($('.js-tab').length) {
			runMixItUpInterval = window.setInterval(module._runMixItUp, 500);
			module._runMixItUp(runMixItUpInterval);
		} else {
			$pods.mixItUp({
				animation: {
					duration: 500,
					effects: 'fade scale stagger',
					perspectiveOrigin: '0 0'
				},
				selectors: {
					target: '.pod-wrapper, .js-filter__item-wrapper'
				},
				callbacks: {
					onMixEnd: function () {
						var $images = $(this).find('img');
						publish('/lazyload/image', [$images]);
					}
				}
			});
		}
	},

	_initEvents: function () {
		$filterStatus.on('click', '.js-filter__list-item', module._processStatusClick);
		$('.js-filter__field input').on('change', module._processFilterChange);
		window.onpopstate = module._restoreFromHistory;
	},

	_runMixItUp: function (interval) {

		var runMixItUpInterval = interval;

		if (runMixItUpInterval) {
			if ($('.pods').length) {
				window.clearInterval(runMixItUpInterval);
				runMixItUpInterval = undefined;
				var $pods = $('.pods');


				$pods.mixItUp({
					animation: {
						duration: 500,
						effects: 'fade scale stagger',
						perspectiveOrigin: '0 0'
					},
					selectors: {
						target: '.pod-wrapper, .js-filter__item-wrapper'
					},
					callbacks: {
						onMixEnd: function () {
							var $images = $(this).find('img');
							publish('/lazyload/image', [$images]);
							$pods.removeClass('filtered-loading');
						}
					}
				});
			}
		}
	},

	_processStatusClick: function () {
		var $t = $(this);
		var name = $t.data('filter-name');
		var val = $t.data('filter-value');
		var target = $('.js-filter__field[data-field-name=' + name + ']');

		target.find('input[value=' + val + ']').prop('checked', false);
		module._destroyFilter(name, val);
	},

	_processFilterChange: function (event) {
		var $t = $(this);
		var name = $t.attr('name');
		var val = $t.attr('value');
		targetFilter = -1;

		// First, check if the box is now being checked, or if it's being unchecked
		if ($t.is(':checked')) {
			// If checked...
			module._buildFilter(name, val);
			// If we're unchecking a box
		} else {
			module._destroyFilter(name, val);
		}
	},

	_buildFilter: function (fName, fValue) {
		// Run along the active filters, and see if there's a filter already running for this field
		for (var i = 0; i < activeFilters.length; i++) {
			var foundFilter = $.inArray(fName, activeFilters[i]);
			// If there is...
			if (foundFilter > -1) {
				// Add the new value to the field
				targetFilter = activeFilters[i];
				targetFilter[1].push(fValue);
			}
		}
		// If we can't find an active filter for this field, we'll make one!
		if (targetFilter === -1) {
			activeFilters.push([fName, [fValue]]);
		}

		module._runFilter();

		if (historySupport) {
			var qstr = "";
			var mt = [];
			// document.location.search = "";
			for (var fk = 0; fk < activeFilters.length; fk++) {
				qstr = activeFilters[fk][1].join(',');
				mt.push(activeFilters[fk][0]);
				mt.push(qstr);
			}
			module._setQueryStringParam.apply(null, mt);
		}
	},

	_destroyFilter: function (fName, fValue) {
		// Run along the active filters, and find the filter running for this field
		for (var i = 0; i < activeFilters.length; i++) {
			var foundFilter = $.inArray(fName, activeFilters[i]);
			// If there is...
			if (foundFilter > -1) {
				targetFilter = activeFilters[i];
				// Check the length of the array.
				// If it's 1...
				if (targetFilter[1].length === 1) {
					// Set up the entire filter to be deactivated.
					activeFilters.splice(i, 1);
					module._removeQueryStringParam(fName);
					// Otherwise...
				} else {
					// Find the specific value and target it for removing
					for (var v = 0; v < targetFilter.length; v++) {
						var foundValue = $.inArray(fValue, targetFilter[v]);
						if (foundValue > -1) {
							targetFilter[1].splice(foundValue, 1);
						}
					}
				}
			}
		}
		if (historySupport) {
			var qstr = '';
			var mt = [];
			// document.location.search = "";
			for (var fk = 0; fk < activeFilters.length; fk++) {
				qstr = activeFilters[fk][1].join(',');
				mt.push(activeFilters[fk][0]);
				mt.push(qstr);

				module._setQueryStringParam.apply(null, mt);
			}
			module._runFilter();
		}
	},

	// Run the filter
	_runFilter: function () {
		var filterListing = '';

		if (activeFilters.length > 0) {
			if (!firstRun) {
				firstRun = true;
				$('.pod-wrapper.hide-pod').addClass('unhidden').delay(500).queue(function () {
					$(this).removeClass('hide-pod');
				});
				$('.see-all').remove();
			}

			$filterStatus.removeClass('hidden');

			for (var f = 0; f < activeFilters.length; f++) {
				var filter = activeFilters[f];

				for (var af = 0; af < filter[1].length; af++) {
					filterListing = filterListing + '<span class="js-filter__list-item filter--list-item" data-filter-name="' + filter[0] + '" data-filter-value="' + filter[1][af] + '">' + filter[1][af].replace(/-/g, ' ') + '<span class="iconf iconf-close"></span></span>';
				}
			}
		} else {
			$filterStatus.addClass('hidden');
		}

		var $pods = $('.js-filter__item-wrapper');
		if ($pods.length === 0) {
			$pods = $('.pod-wrapper');
		}

		var $filteredPods = $pods.filter(function () {
			// Set up the matches, swipe right
			var tinder = true;
			// Target the pod-wrapper elements
			var $el = $(this);
			if ($el.is('.js-filter__item-wrapper')) {
				$el = $el.find('.js-filter__item');
			}

			// If filters are active, begin the rest of the function.
			if (activeFilters.length > 0) {
				// For each item in the array...
				for (var f = 0; f < activeFilters.length; f++) {
					// Try an alternative matching service
					var okcupid = false;
					// Store the current filter
					var filter = activeFilters[f];
					// Grab the name of the filter.
					var elementValues = $el.data(filter[0]) || '';

					for (var af = 0; af < filter[1].length; af++) {
						// Grab the filter argument
						var arg = filter[1][af];
						// Put the alternative matches together
						okcupid = okcupid || (elementValues.indexOf(arg) > -1);
					}
					// Put all your matches into one place!
					tinder = tinder && okcupid;
				}
			}
			// Return the value.

			return tinder;
		});

		var $podsContainer = $('.js-filter__items');
		if ($podsContainer.length === 0) {
			$podsContainer = $('.pods');
		}

		$podsContainer.mixItUp('filter', $filteredPods, true, function () {
			var $images = $(this).find('img');
			publish('/lazyload/image', [$images]);
		});

		$('.js-filter__status-list').html(filterListing);
	},

	_restoreFromHistory: function () {
		$('.js-filter__field input').prop('checked', false);
		// Clear all active filters
		activeFilters = [];

		if (historySupport) {
			if (window.location.search !== "") {
				var query = module._getQueryStringObject();

				for (var key in query) {
					if (query.hasOwnProperty(key)) {
						var pair = query[key].split(',');
						activeFilters.push([key, pair]);
					}
				}

				for (var aa = 0; aa < activeFilters.length; aa++) {
					for (var ab = 0; ab < activeFilters[aa][1].length; ab++) {
						var name = activeFilters[aa][0];
						var val = activeFilters[aa][1][ab];
						var target = $('.js-filter__field[data-field-name=' + name + ']');

						target.find('input[value=' + val + ']').prop('checked', true);
					}
				}
			}

			module._runFilter();
		}
	},

	_getQueryStringObject: function () {
		var queryString = document.location.search.substring(1).split('&'),
			query = {},
			i;
		for (i = 0; i < queryString.length; i++) {
			queryString[i] = queryString[i].split('=');
			if (queryString[i][0].indexOf('filter-') > -1 && queryString[i][1] != 'null') {
				query[decodeURIComponent(queryString[i][0])] = decodeURIComponent(queryString[i][1]);
			}
		}
		return query;
	},

	_setQueryStringParam: function (key, val) {
		var query = module._getQueryStringObject();
		var queryString = '?';
		var i;
		var j;

		if (historySupport) {
			for (i = 0; i < arguments.length - 1; i += 2) {
				key = arguments[i];
				val = arguments[i + 1];
				query[key] = val;
				queryString = '?';

				for (j in query) {
					if (query.hasOwnProperty(j)) {
						if (j) {
							queryString += encodeURIComponent(j) + '=' + encodeURIComponent(query[j]) + '&';
						}
					}
				}
				queryString = queryString.substring(0, queryString.length - 1);
			}

			history.pushState({}, document.title, queryString + document.location.hash);
		}
	},

	_removeQueryStringParam: function (key) {
		var query = module._getQueryStringObject();
		var firstQuery = true;
		var url = document.location.pathname;
		var j;

		if (historySupport) {

			query[key] = null;

			for (j in query) {
				if (query.hasOwnProperty(j)) {

					if (firstQuery) {
						url = '?';
					}
					url += encodeURIComponent(j) + '=' + encodeURIComponent(query[j]) + '&';

					firstQuery = false;
				}
			}

			if (!firstQuery && url.length > 1) {
				url = url.substring(0, url.length - 1);
			}

			history.pushState({}, document.title, url + document.location.hash);
		}
	}
};

export { module as ListingFilterSort };