/* app/ui/tooltip/tooltip */
import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';

var Positions = {
	RIGHT: 0,
	LEFT: 1
};

var module = {
	init: function () {
		module._initEvents();
		module._initSubscriptions();
	},

	_initEvents: function () {
		$(document)
			.on('click', module._processOutsideClick)
			.on('click', '.js-tooltip__trigger, .js-tooltip__close', module._processClick);
	},

	_initSubscriptions: function () {
		subscribe('tooltip/open', module._open);
		subscribe('tooltip/close', module._close);
		subscribe('tooltip/closeAll', module._closeAll);
	},

	_processOutsideClick: function (e) {
		var $this = $(e.target);

		if (!$this.closest('.js-tooltip').length) {
			publish('tooltip/closeAll');
		}
	},

	_processClick: function (e) {
		var $tooltip = $(e.target).closest('.js-tooltip');

		if ($tooltip.hasClass('is-open')) {
			publish('tooltip/close', {
				tooltip: $tooltip
			});
		} else {
			publish('tooltip/open', {
				tooltip: $tooltip
			});
		}

		return false;
	},

	_getPosition: function ($tooltip) {
		$tooltip = $tooltip.closest('.js-tooltip');
		var $body = $tooltip.find('.js-tooltip__body'),
			position = Positions.RIGHT,
			isShown = $tooltip.hasClass('is-open');

		if (!isShown) {
			$tooltip.addClass('is-open');
		}

		if (($body.offset().left + $body.outerWidth()) > $(window).width()) {
			position = Positions.LEFT;
		}

		if (!isShown) {
			$tooltip.removeClass('is-open');
		}

		return position;
	},

	_open: function (data) {
		var $tooltip = $(data.tooltip).closest('.js-tooltip'),
			position = module._getPosition($tooltip);

		$tooltip.addClass('is-open');

		if (position === Positions.LEFT) {
			$tooltip.addClass('tooltip--left');
		} else {
			$tooltip.removeClass('tooltip--left');
		}
	},
	_close: function (data) {
		var $tooltip = $(data.tooltip).closest('.js-tooltip');

		$tooltip.removeClass('is-open');
		$tooltip.removeClass('tooltip--left');
	},

	_closeAll: function (data) {
		var $container = $(data && data.container);

		if ($container.length) {
			// Close all tooltips within this container
			$container.find('.js-tooltip.is-open').removeClass('is-open');
		} else {
			// Close all tooltips everywhere
			$('.js-tooltip.is-open').removeClass('is-open');
		}
	}
}

var Tooltip = {
	init: module.init
};

export { Tooltip };