/* ui/pages/homepage */

import $ from 'jquery';
import { Core } from 'Util/core';
import { ListingFilterExpand } from 'App/listing/filter-expand';
import { Pods } from 'App/pods/pods';

var $finder = $('.js-finder');

var Homepage = {
	init: function () {
		if ($finder.length) {
			FilterExpand.init($finder);
			$finder.on('click', '.js-finder-submit', Homepage._finderSubmit());
		}

		$(document).on('click', '.js-anchor-link', smoothScroll);

	},

	_finderSubmit: function (event) {
		event.preventDefault();
		var url = $finder.find('.js-finder-location').find('option:selected').attr('data-href');
		var queryKey = $finder.find('.js-finder-filter').attr('data-filter');
		var categories = $finder.find('.js-finder-filter').find('input:checked');

		if (queryKey !== '' && categories.length) {
			var queryValues = [];

			for (var i = 0; i < categories.length; i++) {
				queryValues.push(categories[i].value);
			}

			var query = encodeURI(queryValues.join(','));
			window.location = url + '?' + queryKey + query;
		} else {
			window.location = url;
		}
	},

	_smoothScrolling: function (e) {

		var $link = $(e.target).closest('.js-anchor-link'),
			href = $link.attr('href'),
			selector,
			$target;

		selector = href.match(/#.*$/);

		if (selector && selector[0]) {
			$target = $(selector[0]);

			if ($target.length) {
				Util.scrollToElmSmart($target, 0, 500);
				e.preventDefault();

				if (window.history && history.pushState) {
					history.pushState({}, '', $link[0].href);
				}
			}
		}
	},
};

export { Homepage };