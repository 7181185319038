/* app/ui/tabs/tabs */
import $ from 'jquery';
import jQuery from 'jquery';
import { Core } from 'Util/core';
import { publish, unpublish, subscribe } from 'Util/pubsub';

(function ($, Util) {

	var $tabButtons = $('.js-tab-click');
	var $contentTabs = $('.js-tab');
	var $defaultTabButtons = $tabButtons.not('.js-tab-no-default');

	// Show/Hide tabs as appropriate and add to the browser history
	for (var i = 0; i < $defaultTabButtons.length; i++) {
		var path = $defaultTabButtons.eq(i).attr('href'),
			loc = window.location.pathname;

		if (loc.substring(loc.length - 1) !== "/") {
			loc = loc + "/";
		}

		if (
			encodeURI(path) === loc ||
			encodeURI(path) === encodeURI(loc)
		) {
			$defaultTabButtons.eq(i).addClass('tab-current');
			$contentTabs.eq(i).addClass('tab-content-current');
		}
	}

	$tabButtons.on('click', function (event) {
		event.preventDefault();
		var $this = $(this);
		var tabName = $this.attr('data-tab-name');
		var tabLink = $this.attr('href');
		var tabAnchor = $this.attr('data-tab-anchor');
		var $anchor = $('#' + tabAnchor);
		var $thisTab = $contentTabs.filter('[data-tab-name="' + tabName + '"]');
		var $images = $thisTab.find('img');

		var $select = $('.js-tab-select');

		$contentTabs.addClass('hidden');
		$thisTab.removeClass('hidden');
		$tabButtons.removeClass('tab-current');
		$this.addClass('tab-current');

		$contentTabs.removeClass('tab-content-current');
		$thisTab.addClass('tab-content-current');

		if ($anchor.length) {
			tabLink += '#' + tabAnchor;
			Util.scrollToElmSmart($anchor, 0, 500);
		}

		if ($thisTab.find('.pods').length) {
			window.setTimeout(function () { publish('/mixitup/init'); }, 400);
		}
		publish('/lazyload/image', [$images]);

		history.pushState(tabLink, tabName, tabLink);

		if ($select.val() !== $thisTab.attr('data-tab-name')) {
			$select.val($thisTab.attr('data-tab-name'));
		}
	});

	$(document).on('change', '.js-tab-select', function (e) {
		var $select = $('.js-tab-select'),
			$option = $select.find('.js-tab-select-option:selected'),

			$tab = $tabButtons.filter('[data-tab-name="' + $option.attr('value') + '"]');

		if ($tab.length > 1) {
			$tab = $tab.filter('[data-tab-anchor="' + $option.attr('data-tab-anchor') + '"]').first();
		}

		$tab.trigger('click');
	});
}) (jQuery, window, document);