/* app/ui/listing/filter */
import $ from 'jquery';
import { ListingFilterStatus } from 'App/listing/filter-status';
import { Core } from 'Util/core';
import 'throttle';
import 'debounce';
import { subscribe, publish } from 'Util/pubsub';

'use strict';

var $listContainer;
var $filter;
var $fields;
var $status;
var $statusList;
var id;
var initialState = null;
var historySupport = !!(window.history && window.history.pushState);
var filterDelay;
var baseNiceUrl;
var baseAjaxUrl = null;

var module = {

	// See ListingApiController.cs
	// GET: /listing/getlistings/{id}/{listingType}/?page={currentPage}&filter-{filtername}={options}&filter-{filtername}={options}...
	// Example - /listing/getlistings/2526/generic/?page=1&filter-year=2014&filter-location=milford-sound

	init: function ($elm) {
		$listContainer = $('.js-ajax-container');
		id = Math.floor(Math.random() * (1000 - 1) + 1);
		$filter = $elm;
		$fields = $filter.find('.js-filter__field');
		$status = $filter.find('.js-filter__status');
		$statusList = $filter.find('.js-filter__status-list');

		// Get the markup for the initial history state
		initialState = {
			html: $listContainer.html()
		};
		module._configureBaseUrls();
		module._initSubscriptions();
		module._initEvents();

		ListingFilterStatus.init($statusList);
	},

	// Configure URL for filtering and paging API
	// Collects list type and nodeID for API from data attributes
	// Set the baseAjaxUrl for requests
	// Set the baseNiceUrl for history
	_configureBaseUrls: function () {
		var route = '/listing/getlistings/';
		var nodeId = $filter.attr('data-node-id');
		var listingType = $filter.attr('data-listing-type');

		if (parseInt(nodeId, 10) > 0 && listingType !== '') {
			baseAjaxUrl = route + nodeId + '/' + listingType + '/';
			baseNiceUrl = window.location.pathname;
		}
	},

	// Subscribe to ajax/ready event  (published by 'util/ajax.js')
	_initSubscriptions: function () {
		subscribe('/ajax/ready/' + id, module._processAjaxResponse);
	},

	// Listen for history popstate event
	// Listen for change event on filter inputs/selects
	// Listen for clicks on pagination links
	// Listen for clicks on status item buttons
	_initEvents: function () {
		$(window).on('popstate', module._processHistoryPopstate);
		$filter.on('change', 'input', module._processFilterChange);
		$filter.on('click', '.js-filter__list-item', module._processStatusClick);
		$listContainer.on('click', '.js-pagination-btn', module._processPaginationClick);
		$listContainer.on('change', '.js-pagination-mobile', module._processPaginationClick);
	},

	// Determine current state to display
	// Load state HTML
	_processHistoryPopstate: function (event) {
		var currentState = initialState;

		if (event.originalEvent.state) {
			currentState = event.originalEvent.state;
		}

		module._loadNewContent(currentState.html);
	},

	// Set the loading state of the list container to true
	// Clear the filterDelay timeout
	// Process filter request after 1s of no further filter changes
	_processFilterChange: function (event) {
		module._setLoadingState(true);
		clearTimeout(filterDelay);
		filterDelay = setTimeout(module._processFilterRequest, 1000);
	},

	// Determine whether form control has a status element already
	// If not, create one and attach it to the form control
	// Append it to the filter status list
	// Return the status element
	_setStatusElement: function ($select, value) {
		var $statusElm = $select.data('status');

		if (typeof ($statusElm) === 'undefined' || !$statusElm.length) {
			$statusElm = $('<span class="js-filter__list-item filter--list-item"><span class="iconf iconf-close"></span></span>');
			$select.data('status', $statusElm);
		}

		$statusElm.text(value);
		$statusElm.append('<span class="iconf iconf-close"></span>');

		return $statusElm;
	},

	// Return filter query based on all checked/selected options
	// Configure ajaxUrl, appending query to baseAjaxUrl
	// Configure niceUrl, appending query to baseNiceUrl
	// Publish ajax/get event (subscribed by 'util/ajax.js')
	_processFilterRequest: function (pageQuery) {
		var query = pageQuery || module._buildFilterQuery();
		var ajaxUrl = baseAjaxUrl + query;
		var niceUrl = baseNiceUrl + query;

		publish('/ajax/get', { url: ajaxUrl, niceUrl: niceUrl, id: id });
	},

	// Prevent default event on pagination click
	// Retrieve the href which is a query string
	// Set the loading state of the list container to true
	// Process filter request passing through query
	// Scroll page to top of listing container
	_processPaginationClick: function (event) {
		event.preventDefault();
		var pageLink = '';

		if (this.nodeName === 'SELECT') {
			pageLink = $(this).find(':selected').attr('data-page');
		} else {
			pageLink = $(this).attr('href');
		}

		if (pageLink.length) {
			module._setLoadingState(true);
			module._processFilterRequest(pageLink);
			Core._scrollToElmSmart($listContainer, 10);
		}
	},

	// Fine the correlating checkbox
	// Remove the status item
	// Uncheck the checkbox and trigger the change event
	_processStatusClick: function () {
		var $item = $(this);
		var $filterElm = $filter.find('[data-status-value="' + $item.text() + '"]');

		$item.remove();

		if ($statusList.children().length === 0) {
			$status.addClass('hidden');
		}
		$filterElm.prop('checked', false).trigger('change');
	},

	// Load ajax response HTML
	_processAjaxResponse: function (response) {
		module._loadNewContent(response.html, response.niceUrl);
	},

	// Set the loading state of the list container (bool)
	_setLoadingState: function (loading) {
		publish('/loading/set', { loading: loading, elm: $listContainer });
	},

	// Insert new HTML into list container
	// Find images in new content and force lazyload
	// Set the loading state of the list container to false
	// If history URL exists and history is supported, push new history state
	_loadNewContent: function (html, historyUrl) {
		$listContainer.html(html);
		var $images = $listContainer.find('img');
		publish('/imagegallery/load', [document]);
		publish('/lazyload/image', [$images]);
		module._setLoadingState(false);

		if (historyUrl && historySupport) {
			history.pushState({ html: $listContainer.html() }, '', historyUrl);
		}
	},

	// Buillds up filter query for each category
	// Will return an empty string if category has no selections
	_buildFilterQuery: function () {
		var query = '';
		var firstField = true;
		var statusHtml = [];

		for (var i = 0; i < $fields.length; i++) {
			var $field = $fields.eq(i);
			var fieldName = $field.attr('data-field-name');
			var fieldObject = module._getFieldValue($field, fieldName);
			var fieldValue = fieldObject.value;
			statusHtml.push(fieldObject.statusHtml);

			if (fieldName !== '' && fieldValue !== '') {
				query += (firstField ? '?' : '&') + fieldName + '=' + fieldValue;
				firstField = false;
			}
		}

		if (statusHtml.join('') !== '') {
			$statusList.html(statusHtml.join(''));
			$status.removeClass('hidden');
		} else {
			$status.addClass('hidden');
		}

		return query;
	},

	// Find the selected option if the category field is a 'select' type element
	// If filter type is checkbox list, join all checked items into a comma separated string
	// Return filter value
	_getFieldValue: function ($field, fieldName) {
		var $selectedOptions = $field.find('input:checked');
		var optionsArr = [];
		var statusItems = [];

		$statusList.empty();

		for (var i = 0; i < $selectedOptions.length; i++) {
			var $thisOption = $($selectedOptions[i]);

			optionsArr.push($selectedOptions.eq(i).val());
			statusItems.push(module._createStatusElement($thisOption.val(), $thisOption.attr('data-status-value'), fieldName));
		}

		return {
			value: optionsArr.join(','),
			statusHtml: statusItems.join('')
		};
	},

	_createStatusElement: function (value, displayName, fieldName) {
		return '<span class="js-filter__list-item filter--list-item" data-filter-name="' + fieldName + '" data-filter-value="' + value + '">' + displayName + '<span class="iconf iconf-close"></span></span>';
	}
};

export { module as ListingFilter };